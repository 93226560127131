import { Modal, Form } from 'react-bootstrap';
import useAppStore from '../../stores/AppStore';
import { useState, useEffect } from 'react';

import UIIcon from '../UIIcon';

export default function UIModal({ name, title, icon, centered, fullscreen, size, content, actions, form, formHandle }) {

    const [show, setShow] = useState(false);

    const { showedModal, closeModal } = useAppStore();

    useEffect(() => {
        if(showedModal === name)  {
            setShow(true);
        }
        else {
            setShow(false);
        }
    }, [showedModal]);


    return (<>
        <Modal show={show} onHide={() => closeModal()} centered={centered} fullscreen={fullscreen} size={size} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title><UIIcon name={icon} className="text-primary me-3" />{title}</Modal.Title>
            </Modal.Header>
            {form && <Form onSubmit={formHandle}>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    {actions}
                </Modal.Footer>
            </Form>}
            {!form && <> 
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    {actions}
                </Modal.Footer>
            </>}
        </Modal>
    </>);
}

