import { components } from 'react-select';
import UIIcon from './UIIcon';

export const CustomOption = ({ children, ...props }) => {

  if (props.data.flag !== undefined) {
    return (
      <components.Option {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><span className="me-2" style={{ fontSize: '1.2em' }}>{props.data.flag}</span>
          {children}</span>
      </components.Option>
    );
  }

  if (props.data.icon) {
    return (
      <components.Option {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><UIIcon name={props.data.icon} className="me-2" />
          {children}</span>
      </components.Option>
    );
  }

  if (props.data.washicon) {
    return (
      <components.Option {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><span className="me-2"><i className={"icon-wh-" + props.data.washicon}></i></span>
          {children}</span>
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      <span className={props.data.text_color ? "text-" + props.data.text_color : ""}>
        {children}</span>
    </components.Option>
  );

};

export const CustomSingleValue = ({ children, ...props }) => {

  if (props.data.flag !== undefined) {
    return (
      <components.SingleValue {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><span className="me-2" style={{ fontSize: '1.2em' }}>{props.data.flag}</span>
          {children}</span>
      </components.SingleValue>
    );
  }

  if (props.data.icon) {
    return (
      <components.SingleValue {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><UIIcon name={props.data.icon} className="me-2" />
          {children}</span>
      </components.SingleValue>
    );
  }

  if (props.data.washicon) {
    return (
      <components.SingleValue {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><span className="me-2"><i className={"icon-wh-" + props.data.washicon}></i></span>
          {children}</span>
      </components.SingleValue>
    );
  }

  return (
    <components.SingleValue {...props}>
      <span className={props.data.text_color ? "text-" + props.data.text_color : ""}>
        {children}</span>
    </components.SingleValue>
  );
};

export const CustomMultiValue = ({ children, ...props }) => {

  if (props.data.icon) {
    return (
      <components.MultiValue {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><UIIcon name={props.data.icon} className="me-2" />
          {children}</span>
      </components.MultiValue>
    );
  }

  if (props.data.washicon) {
    return (
      <components.MultiValue {...props}>
        <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><span className="me-2"><i className={"icon-wh-" + props.data.washicon}></i></span>
          {children}</span>
      </components.MultiValue>
    );
  }

  return (
    <components.MultiValue {...props}>
      <span className={props.data.text_color ? "text-" + props.data.text_color : ""}>
        {children}</span>
    </components.MultiValue>
  );
};

export const CustomOptionFont = ({ children, ...props }) => {

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <components.Option {...props}>
      <div className='d-flex'>
        <span>{children}</span>
        <small className='ms-auto text-muted'>{capitalizeFirstLetter(props.data.category)}</small>
      </div>
    </components.Option>
  );
};

