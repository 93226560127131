import React, { useContext } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import UIIcon from '../ui/UIIcon';

import useInventoryStore from '../stores/InventoryStore.jsx';

function TableColSelector() {

  const {
    objectsShowCategory, setObjectsShowCategory,
    objectsShowCollections, setObjectsShowCollections,
    objectsShowLocation, setObjectsShowLocation,
    objectsShowCondition, setObjectsShowCondition,
    objectsShowStatus, setObjectsShowStatus,
    objectsShowCreation, setObjectsShowCreation } = useInventoryStore();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
        Afficher les colonnes
      </Dropdown.Toggle>

      <Dropdown.Menu  style={{ width: 204 }}>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowCategory()}>Catégorie{objectsShowCategory && <UIIcon name="check" className="ms-auto mt-1"/>}</Dropdown.Item>
        {/* <Dropdown.Item className="d-flex" onClick={() => setObjectsShowCollections()}>Collections{objectsShowCollections && <UIIcon name="check" className="ms-auto mt-1"/>}</Dropdown.Item> */}
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowLocation()}>Emplacement{objectsShowLocation && <UIIcon name="check" className="ms-auto mt-1"/>}</Dropdown.Item>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowCondition()}>État{objectsShowCondition && <UIIcon name="check" className="ms-auto mt-1"/>}</Dropdown.Item>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowStatus()}>Statut{objectsShowStatus && <UIIcon name="check" className="ms-auto mt-1"/>}</Dropdown.Item>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowCreation()}>Création{objectsShowCreation && <UIIcon name="check" className="ms-auto mt-1"/>}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TableColSelector;