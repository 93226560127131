import { Link, useNavigate } from "react-router-dom";

import useGuruStore from "../guru/stores/GuruStore";
import useInventoryStore from "../stores/InventoryStore";

import { Button, ButtonGroup } from "react-bootstrap";
import UIIcon from "../ui/UIIcon";

import { getConditionData, getStatusData } from '../libs/utils';

export default function ObjectRow({object, pageType}) {

    const { guruAppLang } = useGuruStore();

    const {
        objectsShowCondition,
        objectsShowStatus,
        objectsShowCreation,
        objectsShowLocation,
        objectsShowCategory,
        objectsShowCollections,
        bulkEditMode, bulkEditObjects, setBulkEditObjects
    } = useInventoryStore();

    const navigate = useNavigate();

    return (<>
        <tr>
            {bulkEditMode && <td style={{ lineHeight: '48px', textAlign: 'center', fontSize: '1.4em', width: 50 }}>
                <span onClick={() => bulkEditObjects.includes(object.id) ? setBulkEditObjects(bulkEditObjects.filter((o) => o !== object.id)) : setBulkEditObjects([...bulkEditObjects,object.id])}>
                    <UIIcon name={bulkEditObjects.includes(object.id) ? `check-square`: `square`} />
                </span>
            </td>}
            <td style={{ width: 38, height: 38, textAlign: "center" }}>
                {object.picture && <div className='picture-wrapper'><img src={"https://" + object.picture} style={{ maxWidth: 38, maxHeight: 38, verticalAlign: 'middle' }} alt="object" /></div>}
            </td>
            <td style={{ lineHeight: '24px', width: 350, maxWidth: 350 }}>
                <Link to={`/view/${object.id}`}>{object.name}</Link><br/>
                {object.type === "object" && <><span className='text-muted'>{object.brand}</span> {object.model}</>}
                {object.type === "book" && <>{object.author}</>}
                {object.type === "vinyl" && <>{object.artist}</>}
            </td>
            {pageType !== 'category' && objectsShowCategory && <td style={{ lineHeight: '24px', width: 250, maxWidth: 250 }}>
                <Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br />
                <span className='text-muted'>{object.subcategory}</span>
            </td>}
            {pageType !== 'collection' && objectsShowCollections && <td style={{ lineHeight: '20px', width: 250, maxWidth: 250 }}>{object.collections.map((collection) => (<Link key={collection} to={`/collection/${collection}`} className="text-theme me-2"><small>{collection}</small></Link>))}</td>}
            {pageType !== 'location' && objectsShowLocation && <td className='text-theme' style={{ lineHeight: '24px', width: 250, maxWidth: 250 }}>
                <Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link> {object.location_to_move === '1' && <span className='ms-2 text-muted'><UIIcon name="truck-moving" className="me-1"/>{object.location_next ? object.location_next : ``}</span>}
                <br/><span className='text-muted'>{object.location_storage}</span>
            </td>}
            {pageType !== 'condition' && objectsShowCondition && <td style={{ lineHeight: '48px', width: 150, maxWidth: 150 }}>{object.condition && <><Link to={`/condition/${object.condition}`} className={`text-white`}><UIIcon name={getConditionData(object.condition).icon} className="me-2" />{getConditionData(object.condition).label}</Link></>}</td>}
            {pageType !== 'status' && objectsShowStatus && <td style={{ lineHeight: '48px', width: 140, maxWidth: 140 }}>{object.status && <><Link to={`/status/${object.status}`} className="text-theme"><UIIcon name={getStatusData(object.status).icon} className="me-2" />{getStatusData(object.status).label}</Link></>}</td>}
            {objectsShowCreation && <td className='text-muted' style={{ width: 110, lineHeight: '40px', paddingRight: '20px' }}>{new Date(object.create_date).toLocaleDateString(guruAppLang.replace("_", "-"))}</td>}
            <td style={{ width: 60, lineHeight: '40px', textAlign: "center" }}>
                <ButtonGroup>
                    <Button variant="outline-secondary" onClick={() => navigate(`/duplicate/${object.id}`)}><UIIcon name="copy" /></Button>
                    <Button variant="outline-light" onClick={() => navigate(`/view/${object.id}`)}><UIIcon name="eye" /></Button>
                    <Button variant="outline-primary" onClick={() => navigate(`/edit/${object.id}`)}><UIIcon name="pencil" /></Button>
                </ButtonGroup>
            </td>
        </tr>
    </>)
}