import { CLOUD_URL, APP_URL, PASSPORT_NAME } from "../constants";

export const uploadFile = async (fileData) => {
  const CLOUD_CALL_URL = CLOUD_URL + '/file';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'POST',
    headers: headers,
    body: fileData
  };
  const response = await fetch('https://' + CLOUD_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const deleteFile = async (fileURL) => {
  const CLOUD_CALL_URL = CLOUD_URL + '/file/' + fileURL;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'DELETE',
    headers: headers
  };
  const response = await fetch('https://' + CLOUD_CALL_URL, options);
  const data = await response.json();
  return data;
};