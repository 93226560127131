import { Table } from "react-bootstrap"
import useGuruStore from "../guru/stores/GuruStore"
import useInventoryStore from "../stores/InventoryStore";
import { useState, useEffect } from "react";
import { constants } from '../constants.jsx';
import ObjectRow from "./ObjectRow";
import UIIcon from "../ui/UIIcon";

export default function ObjectsTable({objects, isLocation, isCategory, isCollection, isStatus, isCondition, filter}) {

    const { guruAppThemeMode } = useGuruStore();

    const {
        objectsShowCondition,
        objectsShowStatus,
        objectsShowCreation,
        objectsShowLocation,
        objectsShowCategory,
        objectsShowCollections,
        bulkEditMode
    } = useInventoryStore();


    const [order, setOrder] = useState('name')
    const [order2, setOrder2] = useState("ASC");

    

    const [ObjectsTabled, setObjectsTabled] = useState([]);

    useEffect(() => {
        setObjectsTabled(objects);
    }, [objects]);

    let pageType = "";
    if(isCategory) pageType = 'category';
    if(isCollection) pageType = 'collection';
    if(isLocation) pageType = 'location';
    if(isCondition) pageType = 'condition';
    if(isStatus) pageType = 'status';

    const handleChangeOrder = (event) => {
        const field = event.target.dataset.field;
        const order = event.target.dataset.order ? event.target.dataset.order : order2;
        setOrder(field);
        let newOrder = "";
        if (order === "" || order === "DESC") {
            event.target.dataset.order = "ASC";
            newOrder = 'ASC';
        }
        else if (order === "ASC") {
            event.target.dataset.order = "DESC";
            newOrder = "DESC";
        }
        setOrder2(newOrder);
        console.log(field);
        console.log(newOrder);
        const newObjects = [...objects];
        newObjects.sort((a, b) => {
            let nameA = "";
            let nameB = "";
            if (field === "status") {
                nameA = constants.statusOrder[a[field]] || constants.statusOrder.length + 1;
                nameB = constants.statusOrder[b[field]] || constants.statusOrder.length + 1;
            }
            else if (field === "condition") {
                nameA = constants.conditionsOrder[a[field]] || constants.conditionsOrder.length + 1;
                nameB = constants.conditionsOrder[b[field]] || constants.conditionsOrder.length + 1;
            }
            else {
                nameA = a[field].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase(); // ignore upper and lowercase
                nameB = b[field].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase(); // ignore upper and lowercase
            }

            if (nameA < nameB) {
                return (newOrder === "ASC") ? -1 : 1;
            }
            if (nameA > nameB) {
                return (newOrder === "ASC") ? 1 : -1;
            }
            return 0;
        });
        setObjectsTabled(newObjects);
    }

    return (<>
        <Table bordered responsive variant={guruAppThemeMode} className='nowrap'>
            <thead>
                <tr>
                    {bulkEditMode && <th></th>}
                    <th></th>
                    <th onClick={handleChangeOrder} className={order === "name" ? "active d-flex" : ""} data-field="name">Nom{order === "name" && <UIIcon name={order2 === "ASC" ? "arrow-up-a-z" : "arrow-down-a-z"} className="text-muted ms-auto mt-1" />}</th>
                    {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ? "active d-flex" : ""} data-field="category">Catégorie{order === "category" && <UIIcon name={order2 === "ASC" ? "arrow-up-a-z" : "arrow-down-a-z"} className="text-muted ms-auto mt-1" />}</th>}
                    {!isCollection && objectsShowCollections && <th onClick={handleChangeOrder} className={order === "collections" ? "active d-flex" : ""} data-field="collections">Collections{order === "collections" && <UIIcon name={order2 === "ASC" ? "arrow-up-a-z" : "arrow-down-a-z"} className="text-muted ms-auto mt-1" />}</th>}
                    {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ? "active d-flex" : ""} data-field="location_place">Emplacement{order === "location_place" && <UIIcon name={order2 === "ASC" ? "arrow-up-a-z" : "arrow-down-a-z"} className="text-muted ms-auto mt-1" />}</th>}
                    {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ? "active d-flex" : ""} data-field="condition">État</th>}
                    {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ? "active d-flex" : ""} data-field="status">Statut</th>}
                    {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ? "active d-flex" : ""} data-field="create_date">Création{order === "create_date" && <UIIcon name={order2 === "ASC" ? "arrow-up-1-9" : "arrow-down-1-9"} className="text-muted ms-auto mt-1" />}</th>}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {ObjectsTabled.map((object) => {
                    return (<ObjectRow 
                        key={object.id + " " + object.name}
                        object={object} pageType={pageType}
                    />)
                })}
            </tbody>
        </Table>
    </>)
}