import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead, AsyncTypeahead, Input } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

// import { fetchCity } from "../../services/genealogy";

// import './style.scss';

function UIFieldAutocomplete({ label, options, placeholder, value, setValue, onSearch, inactive, async, noPadding, className, allowNew, newLabel, id }) {

    const [selectedOption, setSelectedOptions] = useState([]);
    const [allOptions, setAllOptions] = useState([]);
    const [currentValue, setCurrentValue] = useState(value);

    // useEffect(() => {
    //     setAllOptions(options);
    //     setSelectedOptions(options.filter((option) => option.city === value));
    // }, [options]);

    useEffect(() => {
        if(currentValue && currentValue !== value) {
            const newValue = currentValue?.label ? currentValue.label : currentValue;
            setValue(newValue);
        }
        
    }, [currentValue]);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    if(async) {
        return (<>
            <Form.Group className="px-2">
                {label && <Form.Label className='text-muted'>{label}</Form.Label>}
                {inactive && <Form.Control
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => { setValue(e.target.value, {}) }}
                />}
                {!inactive && <AsyncTypeahead
                    placeholder={placeholder}
                    options={allOptions}
                    selected={selectedOption}
                    isLoading={false}
                    labelKey={option => `${option.city}`}
                    onSearch={(q) => { console.log('search'); onSearch(q) }}
                    id="birthCity"
                    onChange={(e) => { console.log("change"); setSelectedOptions(e); if (e[0]) { console.log("ok"); setValue(e[0].city, e[0]) } }}
                    onInputChange={(t) => { console.log("inputChange"); setSelectedOptions([]); onSearch(t); setValue(t, {}) }}
                />}
            </Form.Group>
        </>)
    }

    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <Typeahead
                id={id}
                placeholder={placeholder}
                options={options}
                selected={options.filter((o) => o === currentValue)}
                onChange={(v) => setCurrentValue(v.shift())}
                // onInputChange={(v) => { console.log("e"); setCurrentValue(v)}}
                allowNew={allowNew}
                newSelectionPrefix={newLabel}
            />
        </Form.Group>
    </>)

    
}

export default UIFieldAutocomplete;