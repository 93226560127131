import React from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

import UIIcon from "../UIIcon";

import './style.scss';

function UIFieldNumber({ label, placeholder, value, setValue, min, max, zeroFill, noPadding, className }) {
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <InputGroup>
                <Button
                    variant='outline-secondary'
                    onClick={() => {
                        if (!min || (min && parseInt(value) > min)) {
                            value--;
                            if (zeroFill) {
                                value = ('00' + value).slice(-2);
                            }
                            setValue(value + "");
                        }
                    }}
                    tabIndex='-1'
                >
                    <UIIcon name="minus" />
                </Button>
                <Form.Control
                    type={"text"}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        let res = e.target.value;
                        res = res.replace(/[^\d]/g, '');
                        console.log(res);
                        if (res) {
                            res = parseInt(res);
                            if (min && res < min) {
                                res = min;
                            }
                            if (max && res > max) {
                                res = max;
                            }
                        }
                        if (zeroFill) {
                            res = ('00' + res).slice(-2);
                        }

                        setValue(res + "");
                    }}
                />
                <Button
                    variant='outline-secondary'
                    onClick={() => {
                        if (!max || (max && parseInt(value) < max)) {
                            value++;
                            if (zeroFill) {
                                value = ('00' + value).slice(-2);
                            }
                            setValue(value + "");
                        }
                    }}
                    tabIndex='-1'
                >
                    <UIIcon name="plus" />
                </Button>
            </InputGroup>
        </Form.Group>
    </>)
}

export default UIFieldNumber;