import { useState } from "react";
import useInventoryStore from "../../stores/InventoryStore";
import UIModal from "../../ui/UIModal";
import UISelect from "../../ui/UISelect";
import UIButton from "../../ui/UIButton";
import UIIcon from "../../ui/UIIcon";
import useAppStore from "../../stores/AppStore";

export default function ModalChangeCondition() {

    const { updateObjectsCondition } = useInventoryStore();
    const { closeModal } = useAppStore();

    const conditionOptions = [
		{ value: 'new', label: 'Neuf', icon: 'cash-register', text_color: 'green' },
		{ value: 'good', label: 'Bon état', icon: 'thumbs-up', text_color: 'blue' },
		{ value: 'correct', label: 'État correct', icon: 'check', text_color: 'purple' },
		{ value: 'damaged', label: 'Endommagé', icon: 'thumbs-down', text_color: 'orange' },
		{ value: 'hs', label: 'Inutilisable', icon: 'heart-crack', text_color: 'red' },
	];
    const [newCondition, setNewCondition] = useState(null);

    const content = (<>
        <UISelect
            noPadding
            label={`Nouvel état`}
            placeholder={`Sélectionnez le nouvel état`}
            options={conditionOptions}
            value={newCondition ? conditionOptions.filter((o) => o.value === newCondition) : null}
            setValue={(v) => setNewCondition(v)}
        />
    </>);

    const actions = (<>
        <UIButton
            type="submit"
        >
            <UIIcon name="check" className="me-2" />Valider
        </UIButton>
    </>);

    const formHandle = (e) => {
        e.preventDefault();
        console.log(newCondition);
        const result = updateObjectsCondition(newCondition);
        if(result) closeModal();
    }

    return (<>
        <UIModal
            name="ChangeCondition"
            title="Modifier l'état"
            centered
            form
            content={content}
            actions={actions}
            formHandle={formHandle}
        />
    </>)
}