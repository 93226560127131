import React, { useState, useEffect } from 'react';
import ObjectsTables from './ObjectsTables.jsx';

function LocationTable({ objects, setObjects }) {

    const [ storages, setStorages ] = useState([]);

    objects.forEach((object) => {
        if(!storages.includes(object.location_storage)) {
            setStorages([...storages, object.location_storage]);
        }
        
    });

    const view = storages.map((storage) => {
        return (
            <div key={storage}>
                <p className="lead text-theme">{storage}</p>  
                <ObjectsTables isLocation filter={storage} objects={objects} setObjects={setObjects} defaultOrder="location_storage" />
            </div>    
        )
    });

    return (view)

}

export default LocationTable;