import { create } from 'zustand';
import { checkApplication, askUserPassport, askAppPassport, checkPassport, updateApplication } from '../models/guru';
import { PASSPORT_NAME } from '../config';
import { setColors } from '../utils';
import { useTranslation } from 'react-i18next';
const useGuruStore = create((set, get) => ({
    guruIsReady: false,
    guruIsLaunched: false,
    initGuru: async () => {
        console.info('👋 Bienvenue sur Guru');
        console.info('🚔 Vérification de l\'application');
        const checkResponse = await checkApplication();
        const guruAppData = checkResponse?.result;
        
        const guruClientIP = checkResponse?.request?.ip;
        set(() => ({ guruAppData, guruClientIP }));
        get().initGuruApp();
    },
    initGuruApp: () => {
        console.info('🚀 Initialisation de l\'application');
        let guruAppIsAuthenticated = false;
        console.log('📡 Données de l\'application',get().guruAppData);
        get().setGuruAppName(get().guruAppData?.name);
        get().setGuruAppLang(get().guruAppData?.locale);
        get().initGuruAppTheme();
        const guruAppIsInitialized = get().guruAppData.is_init === '1' ? true : false;
        const guruAppIsPrivate = get().guruAppData.is_private === '1' ? true : false;
        const guruAppHasPrivatePassword = get().guruAppData.has_private_password === '1' ? true : false;
        const guruAppAuthorizedIPs = get().guruAppData.private_authorized_ips.split(",");
        const guruAppAuthenticateMode = guruAppIsPrivate ? get().guruAppData.is_authenticated === '1' ? "user" : "app" : null;
        // if(guruAppAuthenticateMode === "app" && guruAppAuthorizedIPs.includes(get().guruClientIP)) get().loginApp({});
        guruAppAuthenticateMode === "app" && console.info('🔐 Authentification requise')
        guruAppAuthenticateMode === "user" && console.info('🔐 Authentification requise')
        // console.log('Authenticate mode : ' + guruAppAuthenticateMode);
        let guruIsReady = false;
        let guruIsLaunched = false;
        if(localStorage.getItem(PASSPORT_NAME)) {
            get().checkPassport();
        }
        else if (guruAppAuthenticateMode === "app" && guruAppAuthorizedIPs.includes(get().guruClientIP)) {
            get().loginApp({})
        }
        else {
           guruIsReady = true;
        //    guruIsLaunched = true;
        }
        set({ guruAppIsInitialized, guruAppIsAuthenticated, guruAppIsPrivate, guruAppHasPrivatePassword, guruAppAuthenticateMode, guruAppAuthorizedIPs, guruIsReady, guruIsLaunched })
    },
    loginUser: async (loginData) => {
        const loginResult = await askUserPassport(loginData);
        console.log('userLogin', loginResult);
        if (loginResult.status === "Error") {
            set({ loginUserError: loginResult.error.API });
            get().setGuruAppLastError(loginResult.error.API);
        }
        if (loginResult.status === "Accepted") {
            // window.location.reload();
            get().checkPassport();
        }
    },
    loginUserError: null,
    loginApp: async (loginData) => {
        const loginResult = await askAppPassport(loginData);
        console.log('userLogin', loginResult);
        if (loginResult.status === "Error") {
            set({ loginAppError: loginResult.error.API });
            get().setGuruAppLastError(loginResult.error.API);
        }
        if (loginResult.status === "Accepted") {
            // window.location.reload();
            get().checkPassport();
        }
    },
    loginAppError: null,
    logout: () => {
        localStorage.removeItem(PASSPORT_NAME);
        window.location.reload();
    },
    checkPassport: async () => {
        console.info("🚔 Vérification de l'authentification");
        const checkResult = await checkPassport();
        if (checkResult.status === "Accepted") {
            // const userIsAdmin = get().appAdminIPs.includes(get().appClientIP) ? true : false;
            set(() => ({ guruAppIsAuthenticated: true, guruIsReady: true, guruIsLaunched: true }));
        }
        else {
            set(() => ({ guruIsReady: true }));
        }
    },
    guruClientIP: null,
    guruAppData: {},
    guruAppName: null,
    guruAppLang: 'fr_FR',
    guruAppIsInitialized: false,
    guruAppIsPrivate: false,
    guruAppIsAuthenticated: false,
    guruAppAuthenticateMode: null,
    guruAppHasPrivatePassword: null,
    guruAppAuthorizedIPs: [],
    guruAppAdminIPs: [],
    setGuruAppName: (guruAppName) => {
        document.title = guruAppName;
        set({ guruAppName });
    },
    updateGuruAppName: async (guruAppName) => {
        const currentGuruAppName = get().guruAppName;
        if (currentGuruAppName !== guruAppName) {
            const appData = {
                application_name: guruAppName
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                get().setGuruAppName(guruAppName);
            }
        }
    },
    setGuruAppLang: (guruAppLang) => {
        localStorage.setItem('guruAppLang', guruAppLang);
        set({ guruAppLang });
    },
    updateGuruAppLang: async (guruAppLang) => {
        const currentGuruAppLang = get().guruAppLang;
        if (currentGuruAppLang !== guruAppLang) {
            const appData = {
                application_locale: guruAppLang
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                get().setGuruAppLang(guruAppLang);
            }
        }
    },
    initGuruAppTheme: () => {
        console.info('🎨 Initialisation du thème');
        const guruAppTheme = get().guruAppData.theme;
        console.log('📡 Données du thème',guruAppTheme);
        const guruAppThemeMode = guruAppTheme.theme;
        get().setGuruAppThemeMode(guruAppThemeMode);
        const guruAppThemeColor = guruAppTheme.color;
        setColors(guruAppThemeColor);
        const guruAppThemeIconClass = guruAppTheme.icon_class;
        const guruAppThemeFontText = guruAppTheme.text_google_font;
        const guruAppThemeFontTitle = guruAppTheme.title_google_font;
        const guruAppThemeFontPageTitle = guruAppTheme.page_title_google_font;
        var WebFont = require('webfontloader');
        WebFont.load({
            google: {
                families: [guruAppThemeFontText, guruAppThemeFontTitle, guruAppThemeFontPageTitle]
            }
        });
        document.querySelector(':root').style.setProperty('--theme-text-font', guruAppThemeFontText);
        document.querySelector(':root').style.setProperty('--theme-title-font', guruAppThemeFontTitle);
        document.querySelector(':root').style.setProperty('--theme-page-title-font', guruAppThemeFontPageTitle);
        
        set({ guruAppThemeColor, guruAppThemeIconClass, guruAppThemeFontText, guruAppThemeFontTitle, guruAppThemeFontPageTitle })
    },
    guruAppThemeMode: localStorage.getItem('guruAppThemeMode') ? localStorage.getItem('guruAppThemeMode') : 'dark',
    setGuruAppThemeMode: (guruAppThemeMode) => {
        localStorage.setItem('guruAppThemeMode', guruAppThemeMode);
        set({ guruAppThemeMode });
    },
    updateGuruAppThemeMode: async (guruAppThemeMode) => {
        const currentGuruAppThemeMode = get().guruAppThemeMode;
        if (currentGuruAppThemeMode !== guruAppThemeMode) {
            const appData = {
                application_theme: guruAppThemeMode
            }
            console.log(appData);
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                get().setGuruAppThemeMode(guruAppThemeMode);
            }
        }
    },
    guruAppThemeColor: localStorage.getItem('guruAppThemeColor') ? localStorage.getItem('guruAppThemeColor') : '#03a9f4',
    setGuruAppThemeColor: (guruAppThemeColor) => {
        localStorage.setItem('guruAppThemeColor', guruAppThemeColor);
        setColors(guruAppThemeColor);
        set({ guruAppThemeColor });
    },
    updateGuruAppThemeColor: async (guruAppThemeColor) => {
        const currentGuruAppThemeColor = get().guruAppThemeColor;
        if (currentGuruAppThemeColor !== guruAppThemeColor) {
            const appData = {
                application_theme_color: guruAppThemeColor
            }
            console.log(appData);
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                get().setGuruAppThemeColor(guruAppThemeColor);
            }
        }
    },
    guruAppThemeFontText: 'Poppins',
    setGuruAppThemeFontText: (guruAppThemeFontText) => {
        var WebFont = require('webfontloader');
        WebFont.load({
            google: {
                families: [guruAppThemeFontText]
            }
        });
        document.querySelector(':root').style.setProperty('--theme-text-font', guruAppThemeFontText);
        set(() => ({ guruAppThemeFontText }));
    },
    updateGuruAppThemeFontText: async (guruAppThemeFontText) => {
        const currentGuruAppThemeFontText = get().guruAppThemeFontText;
        if (currentGuruAppThemeFontText !== guruAppThemeFontText) {
            const appData = {
                application_theme_text_google_font: guruAppThemeFontText
            }
            console.log(appData);
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                get().setGuruAppThemeFontText(guruAppThemeFontText);
            }
        }
    },
    guruAppThemeFontTitle: 'Poppins',
    setGuruAppThemeFontTitle: (guruAppThemeFontTitle) => {
        var WebFont = require('webfontloader');
        WebFont.load({
            google: {
                families: [guruAppThemeFontTitle]
            }
        });
        document.querySelector(':root').style.setProperty('--theme-title-font', guruAppThemeFontTitle);
        set(() => ({ guruAppThemeFontTitle }));
    },
    updateGuruAppThemeFontTitle: async (guruAppThemeFontTitle) => {
        const currentGuruAppThemeFontTitle = get().guruAppThemeFontTitle;
        if (currentGuruAppThemeFontTitle !== guruAppThemeFontTitle) {
            const appData = {
                application_theme_title_google_font: guruAppThemeFontTitle
            }
            console.log(appData);
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                get().setGuruAppThemeFontTitle(guruAppThemeFontTitle);
            }
        }
    },
    guruAppThemeFontPageTitle: 'Poppins',
    setGuruAppThemeFontPageTitle: (guruAppThemeFontPageTitle) => {
        var WebFont = require('webfontloader');
        WebFont.load({
            google: {
                families: [guruAppThemeFontPageTitle]
            }
        });
        document.querySelector(':root').style.setProperty('--theme-page-title-font', guruAppThemeFontPageTitle);
        set(() => ({ guruAppThemeFontPageTitle }));
    },
    updateGuruAppThemeFontPageTitle: async (guruAppThemeFontPageTitle) => {
        const currentGuruAppThemeFontPageTitle = get().guruAppThemeFontPageTitle;
        if (currentGuruAppThemeFontPageTitle !== guruAppThemeFontPageTitle) {
            const appData = {
                application_theme_page_title_google_font: guruAppThemeFontPageTitle
            }
            console.log(appData);
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                get().setGuruAppThemeFontPageTitle(guruAppThemeFontPageTitle);
            }
        }
    },
    guruAppThemeIconClass: 'regular',
    updateGuruAppThemeIconClass: async (guruAppThemeIconClass) => {
        const currentGuruAppThemeIconClass = get().guruAppThemeIconClass;
        if (currentGuruAppThemeIconClass !== guruAppThemeIconClass) {
            const appData = {
                application_theme_icon_class: guruAppThemeIconClass
            }
            console.log(appData);
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                set({ guruAppThemeIconClass });
            }
        }
    },
    guruAppLastError: [],
    setGuruAppLastError: (error) => {
        console.info("setGuruAppLastError");
        const guruAppLastError = get().guruAppLastError;
        const newError = {
            type: error,
            id: Date.now()
        }
        guruAppLastError.push(newError);
        set(() => ({ guruAppLastError }))
    },
    unsetGuruAppLastError: (id) => {
        console.info("unsetGuruAppLastError");
        let guruAppLastError = get().guruAppLastError.filter((error) => error.id !== id);
        set(() => ({ guruAppLastError }));
    },
    guruAppLastAction: [],
    setGuruAppLastAction: (action) => {
        console.info("setGuruAppLastAction");
        const guruAppLastAction = get().guruAppLastAction;
        const newAction = {
            type: action,
            id: Date.now()
        }
        guruAppLastAction.push(newAction);
        set(() => ({ guruAppLastAction }))
    },
    unsetGuruAppLastAction: (id) => {
        console.info("unsetGuruAppLastAction");
        let guruAppLastAction = get().guruAppLastAction.filter((action) => action.id !== id);
        set(() => ({ guruAppLastAction }));
    },
}));

export default useGuruStore;