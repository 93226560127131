import React from 'react';
import { Link } from "react-router-dom";

import useGuruStore from '../guru/stores/GuruStore';
import useAppStore from '../stores/AppStore';

import UIIcon from '../ui/UIIcon';

function AppHeader({isHome}) {

    const { guruAppName, logout } = useGuruStore();
    const { showMenu } = useAppStore();

    if(isHome) {
        return (
            <>
                <header className="App-header">
                    <h1>{guruAppName}</h1>
                </header>
                <nav className='hamburger'>
                    <span onClick={() => showMenu("MenuApp")}><UIIcon name="chevron-right" /></span>
                </nav>
                <nav className='hamburger right'>
                    <span onClick={() => logout()}><UIIcon name="power-off" /></span>
                </nav>
            </>
        )
    }

    return (
        <>
            <header className="App-header">
                <h1><Link to="/">{guruAppName}</Link></h1>
            </header>
            <nav className='hamburger'>
                <span onClick={() => showMenu("MenuApp")}><UIIcon name="chevron-right" /></span>
            </nav>
            <nav className='hamburger right'>
                    <span onClick={() => logout()}><UIIcon name="power-off" /></span>
                </nav>
        </>
    )

}

export default AppHeader;