import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Table, Button, ButtonGroup } from 'react-bootstrap';

import { constants } from '../constants.jsx';
import { setColors, getConditionData, getStatusData } from '../libs/utils.js';

import UIIcon from '../ui/UIIcon/index.jsx';
import useGuruStore from '../guru/stores/GuruStore.jsx';
import useInventoryStore from '../stores/InventoryStore.jsx';
import ObjectRow from './ObjectRow.jsx';
import ObjectsTable from './ObjectsTable.jsx';

function ObjectsTables({ objects, isLocation, isCategory, isCollection, isStatus, isCondition, filter }) {

    const {
        objectsViewType,
    } = useInventoryStore();

    let currentObjects = objects;
    if (isCategory !== undefined) {
        let results = objects.filter((object) => {
            return (object.subcategory == filter);
        });
        currentObjects = results;
    }

    if (isLocation !== undefined) {
        let results = objects.filter((object) => {
            return (object.location_storage == filter);
        });
        currentObjects = results;
    }

    if (objectsViewType !== "all" && !isCategory && !isLocation && !isStatus && !isCondition) {
        return (
            <ObjectsTable
                objects={currentObjects} isLocation={isLocation} isCategory={isCategory} isCollection={isCollection} isStatus={isStatus} isCondition={isCondition} filter={filter}
            />
        )
    }

    return (<>
        {currentObjects.filter(object => object.type === "object").length > 0 && <>
            <p className='text-start'><UIIcon name="rectangle-barcode" className="me-2" />Objets</p>
            <ObjectsTable
                objects={currentObjects.filter(object => object.type === "object")} isLocation={isLocation} isCategory={isCategory} isCollection={isCollection} isStatus={isStatus} isCondition={isCondition} filter={filter}
            />
        </>}
        {currentObjects.filter(object => object.type === "book").length > 0 && <>
            <p className='text-start'><UIIcon name="books" className="me-2" />Livres</p>
            <ObjectsTable
                objects={currentObjects.filter(object => object.type === "book")} isLocation={isLocation} isCategory={isCategory} isCollection={isCollection} isStatus={isStatus} isCondition={isCondition} filter={filter}
            />
        </>}
        {currentObjects.filter(object => object.type === "clothes").length > 0 && <>
            <p className='text-start'><UIIcon name="clothes-hanger" className="me-2" />Vêtements</p>
            <ObjectsTable
                objects={currentObjects.filter(object => object.type === "clothes")} isLocation={isLocation} isCategory={isCategory} isCollection={isCollection} isStatus={isStatus} isCondition={isCondition} filter={filter}
            />
        </>}
        {currentObjects.filter(object => object.type === "vinyl").length > 0 && <>
            <p className='text-start'><UIIcon name="turntable" className="me-2" />Vinyl</p>
            <ObjectsTable
                objects={currentObjects.filter(object => object.type === "vinyl")} isLocation={isLocation} isCategory={isCategory} isCollection={isCollection} isStatus={isStatus} isCondition={isCondition} filter={filter}
            />
        </>}
    </>
    )

}

export default ObjectsTables;