import { create } from 'zustand';
import { editObjectsLocation, editObjectsCondition, editObjectsStatus, fetchLocationPlace, fetchLocationStorage, fetchObjects } from '../services/inventory';

import useGuruStore from '../guru/stores/GuruStore';

const useInventoryStore = create((set, get) => ({
    objectsIsFetched: false,
    fetchData: () => {
        get().fetchObjects();
        get().fetchLocations();
        get().fetchLocationsStorages();
    },
    objectsOriginalData: [],
    objectsData: [],
    objectsShowed: [],
    fetchObjects: async () => {
        console.info('📡 Récupération des objets');
        const objectsResponse = await fetchObjects();
        const objectsData = objectsResponse.result;
        console.log(objectsData);
        set({ objectsData, objectsOriginalData: objectsData });
        get().updateObjectsShowed();
    },
    locationsData: [],
    fetchLocations: async () => {
        const locationsResponse = await fetchLocationPlace();
        const locationsData = locationsResponse.result;
        console.log(locationsData); 
        set({ locationsData });
    },
    locationsStoragesData: [],
    fetchLocationsStorages: async () => {
        const locationsResponse = await fetchLocationStorage();
        const locationsStoragesData = locationsResponse.result;
        console.log(locationsStoragesData); 
        set({ locationsStoragesData });
    },
    updateObjectsLocations: async (location, storage, toMove, next) => {
        const bulkEditObjects = get().bulkEditObjects;
        const updateResponse = await editObjectsLocation(bulkEditObjects, location, storage, toMove, next);
        if(updateResponse.status !== "Updated") {
            return false;
        }
        useGuruStore.getState().setGuruAppLastAction('edit_objects');
        get().fetchObjects();
        get().setBulkEditObjects([]);
        get().toggleBulkEditMode();
        return true;
    },
    updateObjectsCondition: async (condition) => {
        const bulkEditObjects = get().bulkEditObjects;
        const updateResponse = await editObjectsCondition(bulkEditObjects,condition);
        if(updateResponse.status !== "Updated") {
            return false;
        }
        useGuruStore.getState().setGuruAppLastAction('edit_objects');
        get().fetchObjects();
        get().setBulkEditObjects([]);
        get().toggleBulkEditMode();
        return true;
    },
    updateObjectsStatus: async (status) => {
        const bulkEditObjects = get().bulkEditObjects;
        const updateResponse = await editObjectsStatus(bulkEditObjects,status);
        if(updateResponse.status !== "Updated") {
            return false;
        }
        useGuruStore.getState().setGuruAppLastAction('edit_objects');
        get().fetchObjects();
        get().setBulkEditObjects([]);
        get().toggleBulkEditMode();
        return true;
    },

    
    objectData: [],
    objectIsEdited: false,
    currentObjectID: null,
    fetchObjecta: (currentObjectID) => {
        console.info('fetchObjecta');
        const objectData = get().objectsData.filter((o) => parseInt(o.id, 10) === parseInt(currentObjectID, 10)).shift();
        console.log('objecstData',objectData);
        set({ objectData, editedObjectData: objectData, currentObjectID})
    },
    setObjectData: (objectData) => {
        console.log(objectData);
        set({ objectData, objectIsEdited: true })
    },
    updateObjectsShowed: () => {
        set({ objectsIsFetched: false });
        const objectsData = get().objectsData;
        const objectsViewType = get().objectsViewType;

        const objectsShowed = objectsViewType === 'all' ? objectsData : objectsData.filter((o) => o.type === objectsViewType);
        set({ objectsShowed, objectsIsFetched: true });
    },
    objectsViewMode: 'table',
    setObjectsViewMode: (objectsViewMode) => {
        set({ objectsViewMode })
    },
    changeObjectsViewMode: () => {
        if(get().objectsViewMode === 'table') {
            set({ objectsViewMode: 'grid'});
        }
        else {
            set({ objectsViewMode: 'table'});
        }
    },
    objectsViewType: 'object',
    setObjectsViewType: (objectsViewType) => {
        set({ objectsViewType });
        get().updateObjectsShowed();
    },
    objectsShowCategory: true,
    setObjectsShowCategory: () => {
        set({ objectsShowCategory: !get().objectsShowCategory })
    },
    objectsShowCollections: false,
    setObjectsShowCollections: () => {
        set({ objectsShowCollections: !get().objectsShowCollections })
    },
    objectsShowLocation: true,
    setObjectsShowLocation: () => {
        set({ objectsShowLocation: !get().objectsShowLocation })
    },
    objectsShowCondition: true,
    setObjectsShowCondition: () => {
        set({ objectsShowCondition: !get().objectsShowCondition })
    },
    objectsShowStatus: true,
    setObjectsShowStatus: () => {
        set({ objectsShowStatus: !get().objectsShowStatus })
    },
    objectsShowCreation: false,
    setObjectsShowCreation: () => {
        set({ objectsShowCreation: !get().objectsShowCreation })
    },
    bulkEditMode: false,
    toggleBulkEditMode: () => {
        set({ bulkEditMode: !get().bulkEditMode, bulkEditObjects: get().bulkEditMode ? [] : get().bulkEditObjects })
    },
    bulkEditObjects: [],
    setBulkEditObjects: (bulkEditObjects) => {
        console.log(bulkEditObjects);
        set({ bulkEditObjects })
    }
}));

export default useInventoryStore;