import React, { useState, useEffect, useLayoutEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Select, { components }  from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomSingleValue, CustomMultiValue } from '../ui/selectComponents';
import UIFieldText from '../ui/UIFieldText';
import useInventoryStore from '../stores/InventoryStore';

const washingInstructionOptions = [
    { value: 'washing-not-allowed', label: 'Ne pas laver', washicon: 'washing-not-allowed' },
    { value: 'washing-hand', label: 'Lavage à la main', washicon: 'washing-hand' },
    { value: 'washing', label: 'Lavage à la machine, normal', washicon: 'washing' },
   
    { value: 'washing-30deg', label: 'Lavage à la machine, à froid', washicon: 'washing-30deg' },
    { value: 'washing-40deg', label: 'Lavage à la machine, à 40°', washicon: 'washing-40deg' },
    { value: 'washing-50deg', label: 'Lavage à la machine, à 50°', washicon: 'washing-50deg' },
    { value: 'washing-60deg', label: 'Lavage à la machine, à 60°', washicon: 'washing-60deg' },
    { value: 'washing-90deg', label: 'Lavage à la machine, à 70°', washicon: 'washing-70deg' },
    { value: 'washing-90deg', label: 'Lavage à la machine, à 90°', washicon: 'washing-90deg' },
    
];



function ObjectDataFormGroup({objectType, objectDatas, setObjectDatas}) {

    const { objectData, setObjectData } = useInventoryStore();

    if(objectType === "book") {
        return (
            <>
                <UIFieldText
                    noPadding className={`mb-3`}
                    label={`Sous-titre du livre`}
                    placeholder="Entrez le sous-titre du livre" 
                    value={objectData.type === "book" && objectData.subtitle ? objectData.subtitle : ""} 
                    setValue={(v) => setObjectData({...objectData, subtitle:v })}
                />
                <UIFieldText
                    noPadding className={`mb-3`}
                    label={`Auteur du livre`}
                    placeholder="Entrez l'auteur du livre" 
                    value={objectData.type === "book" && objectData.author ? objectData.author : ""} 
                    setValue={(v) => setObjectData({...objectData, author:v })}
                />
                <UIFieldText
                    noPadding className={`mb-3`}
                    label={`Éditeur du livre`}
                    placeholder="Entrez l'éditeur du livre" 
                    value={objectData.type === "book" && objectData.editor ? objectData.editor : ""} 
                    setValue={(v) => setObjectData({...objectData, editor:v })}
                />
                <UIFieldText
                    noPadding
                    label={`Collection du livre`}
                    placeholder="Entrez la collection du livre" 
                    value={objectData.type === "book" && objectData.collection ? objectData.collection : ""} 
                    setValue={(v) => setObjectData({...objectData, collection:v })}
                />
            </>
        )
    }
    if(objectType === "clothes") {
        return (
            <>
                <Form.Group className="mb-3">
                    <Form.Label>Marque du vêtement</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Entrez la marque du vêtement" 
                        value={objectType === "clothes" && objectDatas.brand ? objectDatas.brand : ""} 
                        onChange={(event) => {
                            if(objectType === "clothes") {
                                // setObjectData1(event.target.value)
                                setObjectDatas({...objectDatas,brand:event.target.value});
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Taille du vêtement</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Entrez la taille du vêtement" 
                        value={objectType === "clothes" && objectDatas.size ? objectDatas.size : ""} 
                        onChange={(event) => {
                            if(objectType === "clothes") {
                                setObjectDatas({...objectDatas,size:event.target.value});
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Instruction de lavage du vêtement</Form.Label>
                    <Select isMulti
                        placeholder="Sélectionnez les instructions de lavage du vêtement" 
                        options={washingInstructionOptions} 
                        value={objectType === "clothes" && objectDatas.wash_instructions ? objectDatas.wash_instructions : []} 
                        // onChange={selectOptions}
                        onChange={(options) => {
                            // console.log(options);
                            // const result = [];
                            // options.forEach((option) => {
                            //     result.push(option.value);
                            // });
                            if(objectType === "clothes") {
                                // setObjectData2(options);
                                setObjectDatas({...objectDatas,wash_instructions:options});
                            }
                            // console.log(result);

                        }}
                        styles={selectStyles}
                        components={{Option: CustomOption, SingleValue:CustomSingleValue, MultiValue:CustomMultiValue }}
                    />
                </Form.Group>
            </>
        )
    }
    if(objectType === "vinyl") {
        return (
            <UIFieldText
                noPadding
                label={`Artiste du vinyl`}
                placeholder="Entrez l'artiste du vinyl" 
                value={objectData.type === "vinyl" && objectData.artist ? objectData.artist : ""} 
                setValue={(v) => setObjectData({...objectData, artist:v })}
            />
        )
    }
    return (
        <>
            <UIFieldText
                noPadding className={`mb-3`}
                label={`Marque de l'objet`}
                placeholder="Entrez la marque de l'objet" 
                value={objectData.type === "object" && objectData.brand ? objectData.brand : ""} 
                setValue={(v) => setObjectData({...objectData, brand:v })}
            />
            <UIFieldText
                noPadding
                label={`Modèle de l'objet`}
                placeholder="Entrez la modèle de l'objet" 
                value={objectData.type === "object" && objectData.model ? objectData.model : ""} 
                setValue={(v) => setObjectData({...objectData, model:v })}
            />
        </>
    )
}

export default ObjectDataFormGroup;