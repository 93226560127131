import { create } from 'zustand';

import useInventoryStore from './InventoryStore';

const useAppStore = create((set, get) => ({
    isWorking: true,
    startWorking: () => {
        console.info("🧭 Start Working");
        set(() => ({ isWorking: true }));
    },
    stopWorking: () => {
        console.info("🏁 Stop Working");
        set(() => ({ isWorking: false }));
    },
    screenSize: {},
    getCurrentScreenSize: () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    },
    setCurrentScreenSize: () => {
        const screenSize = get().getCurrentScreenSize();
        console.info(screenSize);
        const isSmall = screenSize.width < 576 ? true : false;
        if(isSmall) {
            const { setObjectsViewMode } = useInventoryStore.getState();
            setObjectsViewMode("grid");
        }
        set({ screenSize, isSmall });
    },
    isSmall: false,
        showedModal: null,
    // showedModalMode: null,
    showModal: (modal, mode) => {
        set((state) => ({ showedModal: modal, showedModalMode: mode }))
    },
    closeModal: () => {
        set((state) => ({ showedModal: null, showedModalMode: null }))
    },
    showedMenu: null,
    showMenu: (menu) => {
        set(() => ({ showedMenu: menu }))
    },
    closeMenu: () => {
        set(() => ({ showedMenu: null }))
    },
    // lastAction: [],
    // setLastAction: (action) => {
    //     console.info("setLastAction");
    //     const lastAction = get().lastAction;
    //     const newAction = {
    //         type: action,
    //         id: Date.now()
    //     }
    //     lastAction.push(newAction);
    //     set(() => ({ lastAction }))
    // },
    // unsetLastAction: (id) => {
    //     console.info("unsetLastAction");
    //     let lastAction = get().lastAction.filter((action) => action.id !== id);
    //     set(() => ({ lastAction }));
    // },
    // lastPage: localStorage.getItem("lastPage") ? localStorage.getItem("lastPage") : null,
    // setLastPage: (lastPage) => {
    //     localStorage.setItem("lastPage", lastPage);
    //     set(() => ({ lastPage }));
    // },
    // lastTab: localStorage.getItem("lastTab") ? localStorage.getItem("lastTab") : null,
    // setLastTab: (lastTab) => {
    //     localStorage.setItem("lastTab", lastTab);
    //     set(() => ({ lastTab }));
    // }
}));

export default useAppStore;