import React from 'react';
import { Link } from "react-router-dom";
import { Col, Row, Card, Container, Accordion, Table, Badge } from 'react-bootstrap';

import { getConditionData, getStatusData, formatCurrency } from '../libs/utils.js';

import { constants } from '../constants.jsx';

import UIIcon from '../ui/UIIcon';
import useGuruStore from '../guru/stores/GuruStore';


function ObjectCard({ object }) {

    const { guruAppThemeMode } = useGuruStore();

    const pictureUrl = 'https://'+object.picture;
    const createDate = new Date(object.create_date).toLocaleDateString("fr");
    const lastUpdateDate = new Date(object.last_update_date).toLocaleDateString("fr");

    console.log(object);

    const conditionData = getConditionData(object.condition);
    const statusData = getStatusData(object.status);


    return ( <>
        <Card style={{ width: '100%' }} bg={guruAppThemeMode === "dark" ? "dark" : ""}>
            <Card.Header>
                <Card.Title className="text-start d-flex mb-0">
                    <div>
                        <strong>{object.name}</strong>
                        {object.type === "book" && <span className='ms-3'>{object.author}</span>}
                    </div>
                    <div class="ms-auto text-end">{object.category}<span className='text-muted ms-3'>{object.subcategory}</span></div></Card.Title>
            </Card.Header>
            <Card.Body className='px-1'>
                <div className="d-flex flex-column flex-sm-row">
                    <div className="text-center text-sm-start me-sm-0">
                        <Card.Img variant="" src={pictureUrl} style={{width:250,height:250,margin:"0 20px 0",objectFit:"contain"}} />
                    </div>
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={3} className="d-flex flex-column justify-content-evenly" style={{height:250}}>
                                {object.type === "object" && object.model && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="tag" className="fa-fw me-2" />Modèle</Card.Text>
                                    <Card.Text className="mb-1 text-center">{object.model}</Card.Text>
                                </div>}
                                {object.type === "object" && object.brand && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="building-columns" className="fa-fw me-2" />Marque</Card.Text>
                                    <Card.Text className="mb-1 text-center">{object.brand}</Card.Text>
                                </div>}
                                {object.type === "book" && object.author && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="feather" className="fa-fw me-2" />Auteur</Card.Text>
                                    <Card.Text className="mb-1 text-center">{object.author}</Card.Text>
                                </div>}
                                {object.type === "book" && object.editor && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="books" className="fa-fw me-2" />Éditeur</Card.Text>
                                    <Card.Text className="mb-1 text-center">{object.editor}</Card.Text>
                                </div>}
                                {object.type === "vinyl" && object.artist && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="microphone-stand" className="fa-fw me-2" />Artiste</Card.Text>
                                    <Card.Text className="mb-1 text-center">{object.artist}</Card.Text>
                                </div>}
                                {object.type === "vinyl" && object.artist && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500">&nbsp;</Card.Text>
                                    <Card.Text className="mb-1 text-center">&nbsp;</Card.Text>
                                </div>}
                            </Col>
                            <Col xs={12} sm={3} className="d-flex flex-column justify-content-evenly" style={{height:250}}>
                                {object.location_place && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="binoculars" className="fa-fw me-2" />Emplacement</Card.Text>
                                    <Card.Text className="mb-1 text-center"><Link to={`/location/${object.location_place}`} className='text-white'>{object.location_place}</Link></Card.Text>
                                </div>}
                                {object.location_storage && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="box-open" className="fa-fw me-2" />Stockage</Card.Text>
                                    <Card.Text className="mb-1 text-center">{object.location_storage}</Card.Text>
                                </div>}
                            </Col>
                            <Col xs={12} sm={3} className="d-flex flex-column justify-content-evenly" style={{height:250}}>
                                {object.condition && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="wine-glass-crack" className="fa-fw me-2" />État</Card.Text>
                                    <Card.Text className="mb-1 text-center"><UIIcon name={conditionData.icon} className="fa-fw me-2" />{conditionData.label}</Card.Text>
                                </div>}
                                {object.status && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="eye" className="fa-fw me-2" />Statut</Card.Text>
                                    <Card.Text className="mb-1 text-center"><UIIcon name={statusData.icon} className="fa-fw me-2" />{statusData.label}</Card.Text>
                                </div>}
                                
                                    
                            </Col>
                            <Col xs={12} sm={3} className="d-flex flex-column justify-content-evenly" style={{height:250}}>
                                {object.acquisition_type && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-600"><UIIcon name="bags-shopping" className="fa-fw me-2" />Acquisition</Card.Text>
                                    <Card.Text className="text-center" style={{marginBottom:20}}>
                                        {object.acquisition_type === "purchase" && (<><UIIcon name="shopping-cart" className="me-2 fa-fw" /><span>{object.acquisition_shop}</span><br/><small>{new Date(object.acquisition_date).toLocaleDateString("fr")}</small></>)}
                                        {object.acquisition_type === "gift" && (<><UIIcon name="gift" className="me-2" /><span>Offert par {object.acquisition_giver}</span></>)}
                                    </Card.Text>
                                </div>}
                                {object.initial_value && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="money-bill" className="fa-fw me-2" />Valeur initiale</Card.Text>
                                    <Card.Text className="mb-1 text-center mb-0">
                                        {object.initial_value && (<span>{formatCurrency(object.initial_value)}</span>)}
                                    </Card.Text>
                                </div>}
                                {object.actual_value && <div>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><UIIcon name="money-bill-trend-up" className="fa-fw me-2" />Valeur actuelle</Card.Text>
                                    <Card.Text className="mb-1 text-center mb-0">
                                        {object.actual_value && (<span>{formatCurrency(object.actual_value)}</span>)}
                                    </Card.Text>
                                </div>}
                            </Col>
                            {/* <Col xs={12} sm={3}>
                                <Card.Text className="mb-1 text-center text-muted"><UIIcon name="files" className="fa-fw me-2" />Fichiers</Card.Text>
                                
                                
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col xs={12} sm={4} className="text-start text-muted">
                        Crée le {createDate}
                    </Col>
                    <Col xs={12} sm={4} className="text-center d-flex justify-content-around">
                        {object.manual && <Card.Text className="mb-0"><a href={`https://${object.manual}`} target="_blank" rel="noreferrer" className='m-auto'><UIIcon name="book-open-cover" className="me-2" />Manuel</a></Card.Text>}
                        {object.acquisition_invoice && <Card.Text className="mb-0"><a href={`https://${object.acquisition_invoice}`} target="_blank" rel="noreferrer" className='m-auto'><UIIcon name="file-invoice-dollar" className="me-2" />Facture</a></Card.Text>}
                    </Col>
                    <Col xs={12} sm={4} className="text-end text-muted">
                        {lastUpdateDate !== "Invalid Date" && <>Modifié le {lastUpdateDate}</>}
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
        <Accordion className='mt-3'>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Nombre de modifications<Badge bg="primary" className='ms-3'>{object.update_count}</Badge></Accordion.Header>
                <Accordion.Body className='p-0'>
                    <Table bordered responsive variant={guruAppThemeMode == "dark" && 'dark'}>
                        <tbody>
                            {object.updates?.map((update) => {
                                return (
                                    <tr key={update.id}>
                                        <td className='text-primary'>{update.data === "object" ? update.type === "UPDATE" ? "SAVE" : "CREATE" : update.type}</td>
                                        {update.data === "object" && <td colSpan={3}>Objet</td>}
                                        {update.data !== "object" && <td>{constants.propertiesLabel[update.data] ? constants.propertiesLabel[update.data] : update.data}</td>}
                                        {update.data !== "object" && <td className='text-muted text-decoration-line-through'>{update.old_value}</td>}
                                        {update.data !== "object" && <td>{update.new_value}</td>}
                                        <td className='nowrap text-muted'>{new Date(update.date).toLocaleString("fr")}</td>
                                        <td className='nowrap text-muted'>{update.updator}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </>    
    )

}

export default ObjectCard;