import { useState } from "react";
import useInventoryStore from "../../stores/InventoryStore";
import UIModal from "../../ui/UIModal";
import UISelect from "../../ui/UISelect";
import UIButton from "../../ui/UIButton";
import UIIcon from "../../ui/UIIcon";
import useAppStore from "../../stores/AppStore";

export default function ModalChangeStatus() {

    const { updateObjectsStatus } = useInventoryStore();
    const { closeModal } = useAppStore();

    const statusOptions = [
		{ value: 'to_keep', label: 'A conserver', icon: 'house' },
		{ value: 'to_repair', label: 'A réparer', icon: 'screwdriver' },
		{ value: 'to_sell', label: 'A revendre', icon: 'sack-dollar' },
		{ value: 'to_give', label: 'A donner', icon: 'hand-holding-heart' },
		{ value: 'to_trash', label: 'A jeter', icon: 'trash' },
		{ value: 'lost', label: 'Perdu', icon: 'question' },
	];
    const [newStatus, setNewStatus] = useState(null);

    const content = (<>
        <UISelect
            noPadding
            label={`Nouveau statut`}
            placeholder={`Sélectionnez le nouveau statut`}
            options={statusOptions}
            value={newStatus ? statusOptions.filter((o) => o.value === newStatus) : null}
            setValue={(v) => setNewStatus(v)}
        />
    </>);

    const actions = (<>
        <UIButton
            type="submit"
        >
            <UIIcon name="check" className="me-2" />Valider
        </UIButton>
    </>);

    const formHandle = (e) => {
        e.preventDefault();
        console.log(newStatus);
        const result = updateObjectsStatus(newStatus);
        if(result) closeModal();
    }

    return (<>
        <UIModal
            name="ChangeStatus"
            title="Modifier le statut"
            centered
            form
            content={content}
            actions={actions}
            formHandle={formHandle}
        />
    </>)
}