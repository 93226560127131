import React, { useState, useEffect } from 'react';
import ObjectsTables from './ObjectsTables.jsx';

function CollectionTable({ objects, setObjects }) {

    return (
        <ObjectsTables isCollection objects={objects} setObjects={setObjects} defaultOrder="status" />
    )

}

export default CollectionTable;