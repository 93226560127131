import { constants } from '../constants.jsx';
import Color from 'color';

export const setColors = (appColor) => {
	if (appColor === undefined) {
		return true;
	}
	const color = Color(appColor);
	const systemColor = color.hex();
	let systemColorRGB = color.rgb();
	systemColorRGB = systemColorRGB.color.join(", ");
	const systemColorDark1 = color.darken(0.15).hex();
	const systemColorDark2 = color.darken(0.3).hex();
	const systemColorDark3 = color.darken(0.45).hex();
	const systemColorDark4 = color.darken(0.6).hex();
	const systemColorLight1 = color.lighten(0.15).hex();
	const systemColorLight2 = color.lighten(0.3).hex();
	const systemColorLight3 = color.lighten(0.45).hex();
	const systemColorLight4 = color.lighten(0.6).hex();
	document.querySelector(':root').style.setProperty('--bs-primary', systemColor);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-dark1', systemColorDark1);
	document.querySelector(':root').style.setProperty('--bs-primary-dark2', systemColorDark2);
	document.querySelector(':root').style.setProperty('--bs-primary-dark3', systemColorDark3);
	document.querySelector(':root').style.setProperty('--bs-primary-dark4', systemColorDark4);
	document.querySelector(':root').style.setProperty('--bs-primary-light1', systemColorLight1);
	document.querySelector(':root').style.setProperty('--bs-primary-light2', systemColorLight2);
	document.querySelector(':root').style.setProperty('--bs-primary-light3', systemColorLight3);
	document.querySelector(':root').style.setProperty('--bs-primary-light4', systemColorLight4);

	return true;
};

export const getConditionData = (conditionName) => {
	if (constants.conditionOptions.filter((condition) => conditionName == condition.value).length > 0) {
		return constants.conditionOptions.filter((condition) => conditionName == condition.value).shift();
	}
	return {
		value: '',
		label: '',
		icon: '',
		text_color: ''
	};
}

export const getStatusData = (statusName) => {
	if (constants.statusOptions.filter((status) => statusName == status.value).length > 0) {
		return constants.statusOptions.filter((status) => statusName == status.value).shift();
	}
	return {
		value: '',
		label: '',
		icon: '',
		text_color: ''
	};
}

export const formatCurrency = (number) => {
	// const formatter = new Intl.NumberFormat(locale, {
	// 	style: 'currency',
	// 	currency: currency,

	// 	// These options are needed to round to whole numbers if that's what you want.
	// 	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	// 	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	//   });
	// return formatter.format(number);
	return number.toString().replace(".", ",") + " €";
};