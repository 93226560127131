import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

import UIIcon from './UIIcon';

function PageBreadcrumb(props) {

    if (!props.page) {
        return (
            <Breadcrumb>
                <Breadcrumb.Item active><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "collection") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>Collection</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "category") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>Catégorie</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "location") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>Emplacement</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "status") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>Statut</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "condition") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>État</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "object") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>Fiche de l'objet</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "add" || props.page === "duplicate") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>Nouvel objet</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "edit") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>Modifier un objet</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    if (props.page === "ui") {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/"><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
                <Breadcrumb.Item active>UI de l'application</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    // return (
    //     <Breadcrumb>
    //         <Breadcrumb.Item active><UIIcon name="home" className="me-2" />Accueil</Breadcrumb.Item>
    //         {/* <Breadcrumb.Item href="#">Home</Breadcrumb.Item> */}
    //         {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
    //         Library
    //         </Breadcrumb.Item>
    //         <Breadcrumb.Item active>Data</Breadcrumb.Item> */}
    //     </Breadcrumb>
    // );

}

export default PageBreadcrumb;