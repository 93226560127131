import React, { useState, useEffect } from 'react';
import ObjectsTables from './ObjectsTables.jsx';

function ConditionTable({ objects, setObjects }) {

    return (
        <ObjectsTables isCondition objects={objects} setObjects={setObjects} defaultOrder="status" />
    )

}

export default ConditionTable;