import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from "react-router-dom";
import { toDataURL } from 'qrcode';

import { fetchCollectionObjects } from '../services/inventory';

import AppHeader from '../components/AppHeader.jsx';
import CollectionTable from '../components/CollectionTable.jsx';

import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import PageBreadcrumb from '../ui/Breadcrumb';

function CollectionView() {

    const params = useParams();

    const [objects, setObjects] = useState([]);
    const [collectionName, setCollectionName] = useState("");
    const [objectsCount, setObjectsCount] = useState(0);
    const [QRsource, setQRsource] = useState("");

    useEffect(() => {
        if (params.name) {
            setCollectionName(params.name);
            fetchCollectionObjects(params.name).then((response) => {
                setObjects(response.result);
                setObjectsCount(response.result.length);
            });
            const url = window.location + "";
            toDataURL(url).then(setQRsource);
        };

    }, [params.name]);

    return (
        <>
            <div className="App">
                <AppHeader />
                <section className="App-section">
                    <Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
                        <div>
                            <h2>{collectionName}</h2>
                            <PageBreadcrumb page="collection" />
                        </div>
                        <p className='ms-auto'><Badge bg="primary" text="black" className='ms-2'>{objectsCount}</Badge> objet{objectsCount > 1 && `s`} dans cette collection</p>
                    </Stack>
                    <CollectionTable objects={objects} setObjects={setObjects} />
                    <div className="d-none d-sm-block">
                        <img src={QRsource} alt="QR Code" />
                    </div>
                </section>
            </div>

        </>
    )

}

export default CollectionView;