import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';


import {
	createObject, fetchObject, editObject, deleteObject,
	fetchCategories, fetchSubcategories, fetchNames, fetchLocationPlace, fetchLocationStorage, fetchCollections
} from '../services/inventory';
import { uploadFile, deleteFile } from '../services/cloud';

import useAppStore from '../stores/AppStore';

import AppHeader from '../components/AppHeader.jsx';
import ObjectDataFormGroup from '../components/ObjectDataFormGroup.jsx';


import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import { constants } from '../constants';
import PageBreadcrumb from '../ui/Breadcrumb';
import UIIcon from '../ui/UIIcon';
import UIEditor from '../ui/UIEditor';

import useGuruStore from '../guru/stores/GuruStore';
import UISelect from '../ui/UISelect';
import UIFieldDate from '../ui/UIFieldDate';
import UIFieldText from '../ui/UIFieldText';
import UIFieldNumber from '../ui/UIFieldNumber';
import UIData from '../ui/UIData';
import UISwitcher from '../ui/UISwitcher';
import useInventoryStore from '../stores/InventoryStore';
import UIFieldAutocomplete from '../ui/UIFieldAutocomplete';

function ObjectForm({ mode }) {

	const params = useParams();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const {
		startWorking, stopWorking
	} = useAppStore();

	const [objectID, setObjectID] = useState("");
	const [object, setObject] = useState("");
	const [objectName, setObjectName] = useState([]);
	const [objectNameList, setObjectNameList] = useState([]);
	const [objectNameListData, setObjectNameListData] = useState([]);
	const [objectType, setObjectType] = useState("object");
	const [objectLocationPlace, setObjectLocationPlace] = useState([]);
	const [objectLocationPlaceList, setObjectLocationPlaceList] = useState([]);
	const [objectLocationStorage, setObjectLocationStorage] = useState([]);
	const [objectLocationStorageList, setObjectLocationStorageList] = useState([]);
	const [objectLocationStorageListData, setObjectLocationStorageListData] = useState([]);
	const [objectCondition, setObjectCondition] = useState("");
	const [objectStatus, setObjectStatus] = useState("");
	const [objectAcquisitionDate, setObjectAcquisitionDate] = useState("");
	const [objectAcquisitionType, setObjectAcquisitionType] = useState("");
	const [objectAcquisitionShop, setObjectAcquisitionShop] = useState("");
	const [objectAcquisitionGiver, setObjectAcquisitionGiver] = useState("");
	const [objectAcquisitionInvoice, setObjectAcquisitionInvoice] = useState("");
	const [objectManual, setObjectManual] = useState("");
	const [objectPicture, setObjectPicture] = useState("");
	const [originalObjectNotes, setOriginalObjectNotes] = useState("");
	const [objectNotes, setObjectNotes] = useState("");
	const [objectInitialValue, setObjectInitialValue] = useState(0);
	const [objectActualValue, setObjectActualValue] = useState(0);
	const [objectUpdates, setObjectUpdates] = useState([]);
	const [objectDatas, setObjectDatas] = useState({});
	const [objectIsMultiple, setObjectIsMultiple] = useState(false);
	const [objectQuantity, setObjectQuantity] = useState(1);

	const { guruAppLang, guruAppThemeMode, setGuruAppLastAction } = useGuruStore();

	const { fetchObjecta, fetchObjects, objectsIsFetched, objectData, setObjectData, objectIsEdited } = useInventoryStore();

	useEffect(() => {
		console.log('objectData', objectData);
	}, [objectData])


	useEffect(() => {
		startWorking();
		console.log('mode', mode);
		if(mode === 'add') {
			setObjectData({
				type: 'object'
			})
		}
	}, [])

	const title = mode === "add" || mode === 'duplicate' ? t('create_object') : t('edit_object');

	const typeOptions = [
		{ value: 'object', label: 'Objet', icon: 'rectangle-barcode' },
		{ value: 'book', label: 'Livre', icon: 'books' },
		{ value: 'clothes', label: 'Vêtement', icon: 'clothes-hanger' },
		{ value: 'vinyl', label: 'Vinyl', icon: 'turntable' },
	];

	const conditionOptions = [
		{ value: 'new', label: 'Neuf', icon: 'cash-register', text_color: 'green' },
		{ value: 'good', label: 'Bon état', icon: 'thumbs-up', text_color: 'blue' },
		{ value: 'correct', label: 'État correct', icon: 'check', text_color: 'purple' },
		{ value: 'damaged', label: 'Endommagé', icon: 'thumbs-down', text_color: 'orange' },
		{ value: 'hs', label: 'Inutilisable', icon: 'heart-crack', text_color: 'red' },
	];

	const statusOptions = [
		{ value: 'to_keep', label: 'A conserver', icon: 'house' },
		{ value: 'to_repair', label: 'A réparer', icon: 'screwdriver' },
		{ value: 'to_sell', label: 'A revendre', icon: 'sack-dollar' },
		{ value: 'to_give', label: 'A donner', icon: 'hand-holding-heart' },
		{ value: 'to_trash', label: 'A jeter', icon: 'trash' },
		{ value: 'lost', label: 'Perdu', icon: 'question' },
	];

	const acquisitionTypeOptions = [
		{ value: 'purchase', label: 'Achat', icon: 'basket-shopping' },
		{ value: 'gift', label: 'Cadeau', icon: 'gift' },
	];

	const washingInstructionOptions = [
		{ value: 'washing', label: 'Lavage à la machine, normal', washicon: 'washing' },
		{ value: 'washing-hand', label: 'Lavage à la main', washicon: 'washing-hand' },
		{ value: 'washing-30deg', label: 'Lavage à la machine, à froid', washicon: 'washing-30deg' },
		{ value: 'washing-not-allowed', label: 'Ne pas laver', washicon: 'washing-not-allowed' },
	];

	const [selectedType, setSelectedType] = useState(typeOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [selectedCondition, setSelectedCondition] = useState(null);
	const [selectedAcquisitionType, setSelectedAcquisitionType] = useState(null);

	const [collectionsOptions, setCollectionsOptions] = useState([]);
	const [objectCollections, setObjectCollections] = useState([]);

	const [categoriesOptions, setCategoriesOptions] = useState([]);
	const [objectCategory, setObjectCategory] = useState("");

	const [subcategoriesOptions, setSubcategoriesOptions] = useState([]);
	const [objectSubcategory, setObjectSubcategory] = useState("");

	useEffect(() => {

		if (params.id) {
			objectsIsFetched && fetchObjecta(params.id);
		}

	}, [params.id, objectsIsFetched, fetchObjecta]);

	useEffect(() => {
		initCollections();
		initCategories();
		initSubcategories();
		if (params.id) {
			
			setObjectID(params.id);
			fetchObject(params.id).then((response) => {
				const item = response.result;
				setObjectName([item.name]);
				// setObjectCat([item.category])
				// setObjectSubcat([item.subcategory])
				setObjectPicture(item.picture);
				setObjectType(item.type);
				setSelectedType(typeOptions[typeOptions.findIndex(p => p.value == item.type)]);
				switch (item.type) {
					case "object":
						setObjectDatas({
							brand: item.brand ? item.brand : "",
							model: item.model ? item.model : "",
						});
						break;
					case "book":
						setObjectDatas({
							author: item.author ? item.author : "",
							editor: item.editor ? item.editor : "",
							collection: item.collection ? item.collection : "",
						});
						break;
					case "clothes":
						const wash_instructions = [];
						item.wash_instructions.split(",").forEach((value) => {
							wash_instructions.push(washingInstructionOptions[washingInstructionOptions.findIndex(p => p.value == value)]);
						});
						setObjectDatas({
							brand: item.brand ? item.brand : "",
							size: item.size ? item.size : "",
							wash_instructions: wash_instructions
						});
						break;
					case "vinyl":
						setObjectDatas({
							artist: item.artist ? item.artist : "",
						});
						break;
					default:
						break;
				}
				setObjectLocationPlace([item.location_place]);
				setObjectLocationStorage([item.location_storage]);
				setObjectCondition(item.condition);
				setSelectedCondition(conditionOptions[conditionOptions.findIndex(p => p.value == item.condition)]);
				setObjectStatus(item.status);
				setSelectedStatus(statusOptions[statusOptions.findIndex(p => p.value == item.status)]);
				setObjectAcquisitionDate(item.acquisition_date.split("-").reverse().join("/"));
				setObjectAcquisitionType(item.acquisition_type);
				setSelectedAcquisitionType(acquisitionTypeOptions[acquisitionTypeOptions.findIndex(p => p.value == item.acquisition_type)]);
				setObjectAcquisitionShop(item.acquisition_shop);
				setObjectAcquisitionGiver(item.acquisition_giver);
				setObjectAcquisitionInvoice(item.acquisition_invoice);
				setObjectManual(item.manual);
				setObjectInitialValue(item.initial_value);
				setObjectActualValue(item.actual_value);
				setObjectNotes(item.notes);
				setOriginalObjectNotes(item.notes);
				setObjectUpdates(item.updates);
				setObjectIsMultiple(item.is_multiple === '0' ? false : true);
				setObjectQuantity(item.quantity ? item.quantity : 1);
				setObject(item);
				setObjectCollections(item.collections);

				setObjectCategory(item.category);

				setObjectSubcategory(item.subcategory);
				stopWorking();
			});

		};
		fetchNames().then((response) => {
			setObjectNameListData(response.result);
			let names = [];
			response.result.forEach((result) => {
				if (result.type === objectType)
					names.push(result.name);
			});
			setObjectNameList(names);
		});
		fetchLocationPlace().then((response) => {
			setObjectLocationPlaceList(response.result);
		});
		fetchLocationStorage().then((response) => {
			setObjectLocationStorageListData(response.result);
			let locations = [];
			response.result.forEach((result) => {
				if (result.location_place == objectLocationPlace[0])
					locations.push(result.location_storage);
			});
			// console.log(locations);
			setObjectLocationStorageList(locations);
		});

	}, [objectsIsFetched]);

	const initCollections = async () => {
		const collectionsResults = await fetchCollections();
		const collectionsData = [];
		collectionsResults.result.forEach((collection) => {
			const collectionData = {
				value: collection.name,
				label: collection.name,
			}
			collectionsData.push(collectionData);
		});
		setCollectionsOptions(collectionsData);
	}

	const initCategories = async () => {
		const categoriesResults = await fetchCategories();
		const categoriesData = [];
		categoriesResults.result.forEach((category) => {
			const categoryData = {
				value: category.name,
				label: category.name,
				type: category.type
			}
			categoriesData.push(categoryData);
		});
		setCategoriesOptions(categoriesData);
	}

	const initSubcategories = async () => {
		const subcategoriesResults = await fetchSubcategories();
		const subcategoriesData = [];
		subcategoriesResults.result.forEach((subcategory) => {
			const subcategoryData = {
				value: subcategory.name,
				label: subcategory.name,
				type: subcategory.type,
				category: subcategory.category
			}
			subcategoriesData.push(subcategoryData);
		});
		setSubcategoriesOptions(subcategoriesData);
	}


	useEffect(() => {
		let names = [];
		objectNameListData.forEach((result) => {
			if (result.type === objectType)
				names.push(result.name);
		});
		setObjectNameList(names);
	}, [objectType]);

	useEffect(() => {
		let locations = [];
		objectLocationStorageListData.forEach((result) => {
			if (result.location_place == objectLocationPlace[0])
				locations.push(result.location_storage);
		});
		setObjectLocationStorageList(locations);
	}, [objectLocationPlace]);

	const handleSubmit = (event) => {
		event.preventDefault();
		let name = objectName;
		if (typeof objectName[0] === 'object') {
			if (objectName[0].customOption) {
				name = [objectName[0].label];
			}
		}
		let locationPlace = objectLocationPlace;
		if (typeof objectLocationPlace[0] === 'object') {
			if (objectLocationPlace[0].customOption) {
				locationPlace = [objectLocationPlace[0].label];
			}
		}
		let locationStorage = objectLocationStorage;
		if (typeof objectLocationStorage[0] === 'object') {
			if (objectLocationStorage[0].customOption) {
				locationStorage = [objectLocationStorage[0].label];
			}
		}
		let objectDatasSend = [];
		switch (objectData.type) {
			case "object":
				objectDatasSend = [
					{
						data_name: 'brand',
						data_value: objectData.brand
					},
					{
						data_name: 'model',
						data_value: objectData.model
					}
				];
				break;
			case "book":
				objectDatasSend = [
					{
						data_name: 'subtitle',
						data_value: objectData.subtitle
					},
					{
						data_name: 'author',
						data_value: objectData.author
					},
					{
						data_name: 'editor',
						data_value: objectData.editor
					},
					{
						data_name: 'collection',
						data_value: objectData.collection
					}
				];
				break;
			case "clothes":
				const washInstructions = [];
				objectDatas.wash_instructions.forEach((option) => {
					washInstructions.push(option.value);
				});
				objectDatasSend = [
					{
						data_name: 'brand',
						data_value: objectDatas.brand
					},
					{
						data_name: 'size',
						data_value: objectDatas.size
					},
					{
						data_name: 'wash_instructions',
						data_value: washInstructions.join(",")
					}
				];
				break;
			case "vinyl":
				objectDatasSend = [
					{
						data_name: 'artist',
						data_value: objectDatas.artist
					}
				];
				break;
			default:
				break;
		}
		const objectData2 = {
			object_name: objectData.name,
			object_category: objectData.category,
			object_subcategory: objectData.subcategory,
			object_type: objectData.type,
			object_collections: objectData.collections,
			object_location_place: locationPlace[0],
			object_location_storage: locationStorage[0],
			object_location_to_move: object.location_to_move,
			object_location_next: object.location_next,
			object_condition: objectCondition,
			object_status: objectStatus,
			object_initial_value: objectInitialValue,
			object_actual_value: objectActualValue,
			object_wanted_value: object.wanted_value,
			object_acquisition_date: objectAcquisitionDate,
			object_acquisition_type: objectAcquisitionType,
			object_acquisition_shop: objectAcquisitionShop,
			object_acquisition_giver: objectAcquisitionGiver,
			object_acquisition_invoice: objectAcquisitionInvoice,
			object_manual: objectManual,
			object_picture: objectPicture,
			object_notes: objectNotes,
			object_datas: objectDatasSend,
			object_is_multiple: objectIsMultiple ? '1' : '0',
			object_quantity: objectQuantity
		};

		if (mode === "add" || mode === "duplicate") {
			createObject(objectData2).then((response) => {
				if (response.status == "Created") {
					setGuruAppLastAction("create_object");
					fetchObjects();
					navigate('/');
				}
			});
		}

		if (mode === "edit") {
			editObject(objectID, objectData2).then((response) => {
				if (response.status === "Updated") {
					setGuruAppLastAction("edit_object");
					fetchObjects();
				}
			});

		}
	}

	const handleDelete = (event) => {
		event.preventDefault();
		deleteObject(objectID).then((response) => {
			if (response.status === "Deleted") {
				localStorage.setItem("lastAction", "delete");
				navigate('/');
			}
		});
	}

	const handlePictureUpload = (event) => {
		event.preventDefault();
		let fileName = "picture_" + objectName;
		fileName = fileName.replaceAll(" ", "-").toLowerCase();
		const filePath = "inventory/pictures/";
		const fileData = new FormData();
		fileData.append("file", event.target.files[0]);
		fileData.append("file_name", fileName);
		fileData.append("file_path", filePath);
		uploadFile(fileData).then((response) => {
			const fileURL = response.result.url;
			setObjectPicture(fileURL);
		});
	}

	const handlePictureDelete = (event) => {
		event.preventDefault();
		deleteFile(objectPicture).then((response) => {
			setObjectPicture("");
		});
	}

	const handleInvoiceUpload = (event) => {
		event.preventDefault();
		let fileName = "invoice_" + objectName;
		fileName = fileName.replaceAll(" ", "-").toLowerCase();
		const filePath = "inventory/invoices/";
		const fileData = new FormData();
		fileData.append("file", event.target.files[0]);
		fileData.append("file_name", fileName);
		fileData.append("file_path", filePath);

		uploadFile(fileData).then((response) => {
			const fileURL = response.result.url;
			setObjectAcquisitionInvoice(fileURL);
		});
	}

	const handleInvoiceDelete = (event) => {
		event.preventDefault();
		deleteFile(objectAcquisitionInvoice).then((response) => {
			setObjectAcquisitionInvoice("");
		});
	}

	const handleManualUpload = (event) => {
		event.preventDefault();

		let fileName = "manual_" + objectName;
		fileName = fileName.replaceAll(" ", "-").toLowerCase();
		const filePath = "inventory/manuals/";

		const fileData = new FormData();
		fileData.append("file", event.target.files[0]);
		fileData.append("file_name", fileName);
		fileData.append("file_path", filePath);

		uploadFile(fileData).then((response) => {
			const fileURL = response.result.url;
			setObjectManual(fileURL);
		});
	}

	const handleManualDelete = (event) => {
		event.preventDefault();
		deleteFile(objectManual).then((response) => {
			setObjectManual("");
		});
	}

	let createDate;
	let lastUpdateDate;
	if (mode === "edit") {
		createDate = new Date(object.create_date).toLocaleString(guruAppLang.substr(0, 2));
		lastUpdateDate = new Date(object.last_update_date).toLocaleString(guruAppLang.substr(0, 2));
	}



	// if (isWorking) {
	// 	return (
	// 		<div className="App-loader">
	// 			<Spinner animation="border" />
	// 			<p className="mt-2">Chargement ...</p>
	// 		</div>
	// 	)
	// }

	return (
		<>
			<div className="App">
				<AppHeader />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2>{title}</h2>
							<PageBreadcrumb page={mode} />
						</div>
						{mode === "edit" && <Link to={`/view/${objectID}`} className="d-none d-sm-block ms-sm-auto"><Button variant="outline-secondary" size="lg"><UIIcon name="note" className="me-2" />Fiche de l'objet</Button></Link>}
					</Stack>
					<Form onSubmit={handleSubmit}>
						<Tabs
							defaultActiveKey="object"
							id="justify-tab-example"
							className="mb-3"
							justify
							variant="pills"
						>
							<Tab eventKey="object" title={<React.Fragment><UIIcon name="rectangle-barcode" className="me-2" />Objet</React.Fragment>}>
								<UISelect
									noPadding className="mb-3"
									label="Type d'objet"
									placeholder="Sélectionnez un type d'objet"
									options={constants.typeOptions}
									value={constants.typeOptions.filter((o) => o.value === objectData.type)}
									setValue={(v) => setObjectData({...objectData, type:v })}
								/>
								<UISelect
									noPadding className="mb-3"
									label={`Catégorie ${constants.typesLabel[objectData.type]}`}
									placeholder={`Sélectionnez la catégorie ${constants.typesLabel[objectData.type]}`}
									options={categoriesOptions.filter((option) => option.type === objectData.type)}
									value={categoriesOptions.filter((option) => option.value === objectData.category && option.type === objectData.type)}
									setValue={(v) => setObjectData({...objectData, category:v })}
									isCreatable
									onCreate={(v) => {
										setCategoriesOptions([...categoriesOptions,{value: v, label: v, type: objectData.type}]);
										setObjectData({...objectData, category:v })
									}}
									// formatCreateLabel={(value) => <><span className='text-muted'>{`Nouvelle catégorie : `}</span>{value}</>}
								/>
								<UISelect
									noPadding className="mb-3"
									label={`Sous-catégorie ${constants.typesLabel[objectData.type]}`}
									placeholder={`Sélectionnez la sous-catégorie ${constants.typesLabel[objectData.type]}`}
									options={subcategoriesOptions.filter((option) => option.type === objectData.type && option.category === objectData.category)}
									value={subcategoriesOptions.filter((option) => option.value === objectData.subcategory && option.type === objectData.type && option.category === objectData.category)}
									setValue={(v) => setObjectData({...objectData, subcategory:v })}
									isCreatable
									onCreate={(v) => {
										setSubcategoriesOptions([...subcategoriesOptions,{value: v, label: v, type: objectData.type, category: objectData.category}]);
										setObjectData({...objectData, subcategory:v });
									}}
									// formatCreateLabel={(value) => <><span className='text-muted'>{`Nouvelle sous-catégorie : `}</span>{value}</>}
								/>
								<UISelect
									noPadding className="mb-3"
									label={`Collections ${constants.typesLabel[objectData.type]}`}
									placeholder={`Sélectionnez les collections ${constants.typesLabel[objectData.type]}`}
									options={collectionsOptions}
									value={collectionsOptions.filter((option) => objectData.collections?.includes(option.value))}
									setValue={(v) => setObjectData({...objectData, collections:v})}
									isCreatable isMulti
									onCreate={(v) => {
										setCollectionsOptions([...collectionsOptions,{value: v, label: v}]);
										setObjectData({...objectData, collections:[...objectData.collections,v]});
									}}
									// formatCreateLabel={(value) => <><span className='text-muted'>{`Nouvelle catégorie : `}</span>{value}</>}
								/>
							</Tab>
							<Tab eventKey="description" title={<React.Fragment><UIIcon name="font" className="me-2" />Description</React.Fragment>}>
								{objectData.type === "object" && <UIFieldAutocomplete 
									noPadding className={`mb-3`}
									id={'inputName'}
									label={`Nom ${constants.typesLabel[objectData.type]}`}
									placeholder={`Entrez le nom ${constants.typesLabel[objectData.type]}`}
									options={objectNameList}
									value={objectData.name}
									setValue={(v) => setObjectData({...objectData, name:v })}
									allowNew
									newLabel='Nouvel élément : '
								/>}
								{objectData.type !== "object" && <UIFieldText
									noPadding className={`mb-3`}
									label={`Nom ${constants.typesLabel[objectData.type]}`}
									placeholder={`Entrez le nom ${constants.typesLabel[objectData.type]}`}
									value={objectData.name}
									setValue={(v) => setObjectData({...objectData, name:v })}
								/>}
								<ObjectDataFormGroup
									objectType={objectData.type}
									objectDatas={objectDatas}
									setObjectDatas={setObjectDatas}
								/>
							</Tab>
							<Tab eventKey="acquisition" title={<React.Fragment><UIIcon name="bags-shopping" className="me-2" />Acquisition</React.Fragment>}>
								<UIFieldDate
									noPadding className="mb-3"
									label={`Date d'acquisition ${constants.typesLabel[objectType]}`}
									placeholder={`Entrez la date d'acquisition ${constants.typesLabel[objectType]}`}
									value={objectAcquisitionDate}
									setValue={(v) => setObjectAcquisitionDate(v)}
								/>
								<UISelect
									noPadding className="mb-3"
									label={`Type d'acquisition ${constants.typesLabel[objectType]}`}
									placeholder={`Sélectionnez le type d'acquisition ${constants.typesLabel[objectType]}`}
									options={acquisitionTypeOptions}
									value={acquisitionTypeOptions.filter((o) => o.value === objectAcquisitionType)}
									setValue={(v) => setObjectAcquisitionType(v)}
								/>
								{objectAcquisitionType === "purchase" && <UIFieldText 
									noPadding className="mb-3"
									label={`Magasin d'achat ${constants.typesLabel[objectType]}`}
									placeholder="Entrez le magasin d'achat de l'objet"
									value={objectAcquisitionShop}
									setValue={(v) => setObjectAcquisitionShop(v)}
								/>}
								{objectAcquisitionType === "gift" && <UIFieldText 
									noPadding className="mb-3"
									label={`Offreur ${constants.typesLabel[objectType]}`}
									placeholder="Entrez l'offreur' de l'objet"
									value={objectAcquisitionGiver}
									setValue={(v) => setObjectAcquisitionGiver(v)}
								/>}
							</Tab>
							<Tab eventKey="state" title={<React.Fragment><UIIcon name="wine-glass-crack" className="me-2" />État</React.Fragment>}>
								<UISelect
									noPadding className="mb-3"
									label="État de l'objet"
									placeholder="Sélectionnez l'état actuel de l'objet"
									options={conditionOptions}
									value={conditionOptions.filter((o) => o.value === objectCondition)}
									setValue={(v) => setObjectCondition(v)}
									// isClearable
								/>
								<UISelect
									noPadding
									label="Statut de l'objet"
									placeholder="Sélectionnez le statut actuel de l'objet"
									options={statusOptions}
									value={statusOptions.filter((o) => o.value === objectStatus)}
									setValue={(v) => setObjectStatus(v)}
									// isClearable
								/>
								{objectStatus === 'to_sell' && <UIFieldNumber
									noPadding className="mt-3"
									label={`Valeur de revente ${constants.typesLabel[objectType]}`}
									placeholder="Entrez la valeur de revente de l'objet"
									min={0}
									value={object.wanted_value}
									setValue={(v) => setObject({...object, wanted_value: v})}
								/>}
							</Tab>
							<Tab eventKey="location" title={<React.Fragment><UIIcon name="binoculars" className="me-2" />Emplacement</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Emplacement actuel de l'objet</Form.Label>
									{/* <Form.Control 
										type="text" 
										placeholder="Entrez l'emplacement actuel de l'objet" 
										value={objectLocationPlace} 
										onChange={(event) => setObjectLocationPlace(event.target.value)} /> */}
									<Typeahead
										id="location-place-typeahead"
										allowNew
										placeholder="Entrez l'emplacement actuel de l'objet"
										selected={objectLocationPlace}
										options={objectLocationPlaceList}
										onChange={(event) => setObjectLocationPlace(event)}
										newSelectionPrefix='Nouvel élément : '
									// onInputChange={(event) => setObjectCat([event])}
									/>
								</Form.Group>
								<UISwitcher 
									noPadding
									label={`Objet à déplacer`}
									value={object.location_to_move === '1'}
									setValue={(v) => setObject({...object, location_to_move: v ? '1' : '0'})}
								/>
								{object.location_to_move === '1' && <UIFieldText 
									noPadding className="mt-3"
									label={`Emplacement futur ${constants.typesLabel[objectType]}`}
									placeholder={`Entrez l'emplacement futur ${constants.typesLabel[objectType]}`}
									value={object.location_next}
									setValue={(v) => setObject({...object, location_next: v })}
								/>}
								<Form.Group className="mt-3">
									<Form.Label>Lieu de stockage actuel {constants.typesLabel[objectType]}</Form.Label>
									<Typeahead
										id="location-storage-typeahead"
										allowNew
										placeholder={`Entrez le lieu de stockage ${constants.typesLabel[objectType]}`}
										selected={objectLocationStorage}
										options={objectLocationStorageList}
										onChange={(event) => setObjectLocationStorage(event)}
										newSelectionPrefix='Nouvel élément : '
									/>
								</Form.Group>
								
							</Tab>
							{/* <Tab eventKey="image" title={<React.Fragment><UIIcon name="camera" className="me-2" />Image</React.Fragment>}>
								
							</Tab> */}
							<Tab eventKey="value" title={<React.Fragment><UIIcon name="money-bill" className="me-2" />Valeur</React.Fragment>}>
								<UIFieldNumber
									noPadding className="mb-3"
									label={`Valeur initial ${constants.typesLabel[objectType]}`}
									placeholder="Entrez la valeur initiale de l'objet"
									min={0}
									value={objectInitialValue}
									setValue={(v) => setObjectInitialValue(v)}
								/>
								<UIFieldNumber
									noPadding className="mb-3"
									label={`Valeur actuelle ${constants.typesLabel[objectType]}`}
									placeholder="Entrez la valeur actuelle de l'objet"
									min={0}
									value={objectActualValue}
									setValue={(v) => setObjectActualValue(v)}
								/>
							</Tab>
							<Tab eventKey="files" title={<React.Fragment><UIIcon name="files" className="me-2" />Fichiers</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Image de l'objet</Form.Label>
									{objectPicture !== "" &&
										<div>
											<a href={`https://${objectPicture}`} target="_blank" rel="noreferrer">
												<img src={`https://${objectPicture}`} alt="object" />
											</a>
										</div>}
									{objectPicture === "" && <Form.Control
										type="file"
										onChange={handlePictureUpload}
									/>}
									{objectPicture !== "" && <>
										<div className="d-grid mt-3">
											<Button type="Button" variant="outline-danger" onClick={handlePictureDelete}>Supprimer l'image</Button>
										</div>
									</>}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Facture de l'objet</Form.Label>
									{objectAcquisitionInvoice === "" && <Form.Control
										type="file"
										onChange={handleInvoiceUpload}
									/>}
									{objectAcquisitionInvoice !== "" && <>
										<a href={`https://${objectAcquisitionInvoice}`} target="_blank" rel="noreferrer">
											<div className="d-grid">
												<Button type="Button" variant="outline-secondary">Voir la facture</Button>
											</div>
										</a>
										<div className="d-grid mt-3">
											<Button type="Button" variant="outline-danger" onClick={handleInvoiceDelete}>Supprimer la facture</Button>
										</div>
									</>}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Manuel de l'objet</Form.Label>
									{objectManual === "" && <Form.Control
										type="file"
										onChange={handleManualUpload}
									/>}
									{objectManual !== "" && <>
										<a href={`https://${objectManual}`} target="_blank" rel="noreferrer">
											<div className="d-grid">
												<Button type="Button" variant="outline-secondary">Voir le manuel</Button>
											</div>
										</a>
										<div className="d-grid mt-3">
											<Button type="Button" variant="outline-danger" onClick={handleManualDelete}>Supprimer le manuel</Button>
										</div>
									</>}
								</Form.Group>
							</Tab>
							{/* <Tab eventKey="notes" title={<React.Fragment><UIIcon name="pen-nib" className="me-2" />Notes</React.Fragment>}>
								
							</Tab> */}

							<Tab eventKey="infos" title={<React.Fragment><UIIcon name="info" className="me-2" />Informations</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Notes</Form.Label>
									<UIEditor
										placeholder="Entrez vos notes sur l'objet"
										originalContent={originalObjectNotes}
										content={objectNotes}
										setContent={setObjectNotes}
									/>
									{/* <MDEditor
										value={objectNotes}
										onChange={setObjectNotes}
									/> */}
								</Form.Group>
								{mode === "edit" && <><Stack direction="horizontal" className="mb-3 flex-column flex-sm-row">
									<p className='text-start'><strong>Création</strong><br /><span className='text-muted'>{createDate}<br />{object.creator}</span></p>
									<p className="ms-sm-auto text-end"><strong>Dernière édition</strong><br /><span className='text-muted'>{lastUpdateDate}<br />{object.last_updator}</span></p>
								</Stack>
									<Form.Group className="mb-5">
										{/* <p>Nombre de modifications : </p> */}
										<Accordion>
											<Accordion.Item eventKey="0">
												<Accordion.Header>Nombre de modifications<Badge bg="primary" className='ms-3'>{object.update_count}</Badge></Accordion.Header>
												<Accordion.Body className='p-0'>
													<Table bordered responsive variant={guruAppThemeMode == "dark" && 'dark'}>
														<tbody>
															{objectUpdates.map((update) => {
																return (
																	<tr key={update.id}>
																		<td className='text-primary'>{update.data === "object" ? update.type === "UPDATE" ? "SAVE" : "CREATE" : update.type}</td>
																		{update.data === "object" && <td colSpan={3}>Objet</td>}
																		{update.data !== "object" && <td>{constants.propertiesLabel[update.data] ? constants.propertiesLabel[update.data] : update.data}</td>}
																		{update.data !== "object" && <td className='text-muted text-decoration-line-through'>{update.old_value}</td>}
																		{update.data !== "object" && <td>{update.new_value}</td>}
																		<td className='nowrap text-muted'>{new Date(update.date).toLocaleString("fr")}</td>
																		<td className='nowrap text-muted'>{update.updator}</td>
																	</tr>)
															})}
														</tbody>
													</Table>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</Form.Group>
									<Form.Group className="mb-3 d-grid">
										<Button type="button" variant="danger" size="lg" onClick={handleDelete}>Supprimer l'objet</Button>
									</Form.Group></>}
							</Tab>

							<Tab eventKey="data" title={<React.Fragment><UIIcon name="database" className="me-2" />Données</React.Fragment>}>
								<UIData value={object} />
							</Tab>

						</Tabs>
						<Form.Group className="mt-4 pt-4 mb-3 border-top d-grid">
							<Button type="submit" size="lg">{mode === "add" || mode === 'duplicate' ? <><UIIcon name="plus" className="me-2" />Ajouter</> : <><UIIcon name="check" className="me-2" />Enregistrer</>}</Button>
							<Button variant="secondary" size="lg" className='mt-3' onClick={() => navigate(-1)}><UIIcon name="chevrons-left" className="me-2" />Retour</Button>
						</Form.Group>
					</Form>
				</section>
			</div>
		</>
	);
}

export default ObjectForm;