import { useState, useEffect } from "react";
import useInventoryStore from "../../stores/InventoryStore";
import UIModal from "../../ui/UIModal";
import UISelect from "../../ui/UISelect";
import UIButton from "../../ui/UIButton";
import UIIcon from "../../ui/UIIcon";
import useAppStore from "../../stores/AppStore";
import UISwitcher from "../../ui/UISwitcher";

export default function ModalMoveObjects() {

    const { locationsData, locationsStoragesData, updateObjectsLocations } = useInventoryStore();
    const { closeModal } = useAppStore();

    const [locationsOptions, setLocationsOptions] = useState([]);
    const [locationsStoragesOptions, setLocationsStoragesOptions] = useState([]);
    const [newLocation, setNewLocation] = useState(null);
    const [newLocationToMove, setNewLocationToMove] = useState(false);
    const [newLocationNext, setNewLocationNext] = useState(null);
    const [newLocationStorage, setNewLocationStorage] = useState(null);

    useEffect(() => {
        const options = [];
        locationsData.map((locationData) => {
            const option = {
                value: locationData,
                label: locationData,
            }
            options.push(option);
        });
        setLocationsOptions(options);
    }, [locationsData]);

    useEffect(() => {
        const options = [];
        locationsStoragesData.map((locationData) => {
            const option = {
                value: locationData.location_storage,
                label: locationData.location_storage,
                location: locationData.location_place
            }
            options.push(option);
        });
        setLocationsStoragesOptions(options);
    }, [locationsStoragesData]);

    const content = (<>
        <UISelect
            noPadding
            label={`Emplacement actuel`}
            placeholder={`Sélectionnez le nouvel emplacement actuel`}
            options={locationsOptions}
            value={newLocation ? locationsOptions.filter((o) => o.value === newLocation) : null}
            setValue={(v) => setNewLocation(v)}
        />
        <UISwitcher
            noPadding className={`mt-3`}
            label={'À déplacer'}
            value={newLocationToMove}
            setValue={(v) => setNewLocationToMove(v)}
        />
        {newLocationToMove && <>
            <UISelect
                noPadding className={`mt-3`}
                label={`Emplacement futur`}
                placeholder={`Sélectionnez le nouvel emplacement futur`}
                options={locationsOptions}
                value={newLocationNext ? locationsOptions.filter((o) => o.value === newLocationNext) : null}
                setValue={(v) => setNewLocationNext(v)}
            />
        </>}
        {newLocation && <UISelect
            noPadding className={`mt-3`}
            label={`Lieu de stockage`}
            placeholder={`Sélectionnez le nouveau lieu de stockage`}
            options={newLocation ? locationsStoragesOptions.filter((o) => o.location === newLocation) : []}
            value={newLocationStorage ? locationsStoragesOptions.filter((o) => o.value === newLocationStorage) : null}
            setValue={(v) => setNewLocationStorage(v)}
        />}
    </>);

    const actions = (<>
        <UIButton
            type="submit"
        >
            <UIIcon name="check" className="me-2" />Valider
        </UIButton>
    </>);

    const formHandle = (e) => {
        e.preventDefault();
        console.log(newLocation);
        console.log(newLocationStorage);
        const result = updateObjectsLocations(newLocation, newLocationStorage, newLocationToMove, newLocationNext);
        if(result) closeModal();
    }

    return (<>
        <UIModal
            name="MoveObjects"
            title="Modifier l'emplacement des objets"
            centered
            form
            content={content}
            actions={actions}
            formHandle={formHandle}
        />
    </>)
}