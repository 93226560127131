import React, { useState, useEffect } from "react";

import { Editor, EditorState, RichUtils } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';

import { ButtonGroup, Button } from 'react-bootstrap';
import UIIcon from "../UIIcon";

import './style.scss';

function UIEditor({ originalContent, content, setContent, placeholder }) {

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        setEditorState(EditorState.createWithContent(
            convertFromHTML(originalContent)
        ));
    }, [originalContent]);

    useEffect(() => {
        if (editorState) {
            setContent(convertToHTML(editorState.getCurrentContent()))
        }
    }, [editorState]);

    const updateEditorState = editorState => setEditorState(editorState);

    const applyStyle = style => {
        const editorStateFocused = EditorState.forceSelection(
            editorState,
            editorState.getSelection(),
        );
        updateEditorState(RichUtils.toggleInlineStyle(editorStateFocused, style));
    };

    const isStyleActive = style => {
        const draftStyle = editorState.getCurrentInlineStyle();
        return draftStyle.has(style);
    };

    const applyBloc = style => {
        const editorStateFocused = EditorState.forceSelection(
            editorState,
            editorState.getSelection(),
        );
        updateEditorState(RichUtils.toggleBlockType(editorStateFocused, style));
    };

    const isBlocActive = style => {
        const currentSelection = editorState.getSelection();
        const currentKey = currentSelection.getStartKey();
        const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
        return currentBlock.getType() === style;
    };

    return (<div className="UI-Editor">
        <div className="Editor-toolbar">
            <ButtonGroup size="sm" className='mb-3'>
                <Button
                    variant={isStyleActive("BOLD") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyStyle("BOLD")}>
                    <UIIcon fixedWidth name="bold" />
                </Button>
                <Button
                    variant={isStyleActive("ITALIC") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyStyle("ITALIC")}>
                    <UIIcon fixedWidth name="italic" />
                </Button>
                <Button
                    variant={isStyleActive("UNDERLINE") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyStyle("UNDERLINE")}>
                    <UIIcon fixedWidth name="underline" />
                </Button>
                <Button
                    variant={isStyleActive("STRIKETHROUGH") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyStyle("STRIKETHROUGH")}>
                    <UIIcon fixedWidth name="strikethrough" />
                </Button>
                <Button
                    variant={isStyleActive("CODE") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyStyle("CODE")}>
                    <UIIcon fixedWidth name="code-simple" />
                </Button>
            </ButtonGroup>
            <ButtonGroup size="sm" className='mb-3 ms-3'>
                <Button
                    variant={isBlocActive("unstyled") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("unstyled")}>
                    <UIIcon fixedWidth name="paragraph" />
                </Button>
            </ButtonGroup>
            <ButtonGroup size="sm" className='mb-3 ms-3'>
                <Button
                    variant={isBlocActive("header-one") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("header-one")}>
                    <UIIcon fixedWidth name="h1" />
                </Button>
                <Button
                    variant={isBlocActive("header-two") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("header-two")}>
                    <UIIcon fixedWidth name="h2" />
                </Button>
                <Button
                    variant={isBlocActive("header-three") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("header-three")}>
                    <UIIcon fixedWidth name="h3" />
                </Button>
                <Button
                    variant={isBlocActive("header-four") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("header-four")}>
                    <UIIcon fixedWidth name="h4" />
                </Button>
                <Button
                    variant={isBlocActive("header-five") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("header-five")}>
                    <UIIcon fixedWidth name="h5" />
                </Button>
                <Button
                    variant={isBlocActive("header-six") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("header-six")}>
                    <UIIcon fixedWidth name="h6" />
                </Button>
            </ButtonGroup>
            <ButtonGroup size="sm" className='mb-3 ms-3'>
                <Button
                    variant={isBlocActive("unordered-list-item") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("unordered-list-item")}>
                    <UIIcon fixedWidth name="list" />
                </Button>
                <Button
                    variant={isBlocActive("ordered-list-item") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("ordered-list-item")}>
                    <UIIcon fixedWidth name="list-ol" />
                </Button>
            </ButtonGroup>
            <ButtonGroup size="sm" className='mb-3 ms-3'>
                <Button
                    variant={isBlocActive("blockquote") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("blockquote")}>
                    <UIIcon fixedWidth name="quote-left" />
                </Button>
                <Button
                    variant={isBlocActive("code-block") ? 'secondary' : 'outline-secondary'}
                    onClick={() => applyBloc("code-block")}>
                    <UIIcon fixedWidth name="code" />
                </Button>
            </ButtonGroup>
        </div>
        <div className={`Editor-textarea${hasFocus ? ` focused` : ''}`}>
            <Editor
                editorState={editorState}
                placeholder={placeholder}
                onChange={setEditorState}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
            />
        </div>
    </div>)
}

export default UIEditor