import React, { useState, useEffect } from 'react';
import ObjectsTables from './ObjectsTables.jsx';

function CategoryTable({ objects, setObjects }) {

    const [subcategories, setSubcategories] = useState([]);
    const [objectsBySubcategories, setObjectsBySubcategories] = useState({});

    objects.forEach((object) => {
        if (!subcategories.includes(object.subcategory)) {
            setSubcategories([...subcategories, object.subcategory]);
        }

    });

    useEffect(() => {
        const newSubcategories = [...subcategories];
        newSubcategories.sort((a, b) => {
            const nameA = a.substring(3).toUpperCase(); // ignore upper and lowercase
            const nameB = b.substring(3).toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        setSubcategories(newSubcategories);

    }, [objects]);




    const view = subcategories.map((subcategory) => {
        return (
            <div key={subcategory}>
                <p className="lead text-theme text-start">{subcategory}</p>
                <ObjectsTables isCategory filter={subcategory} objects={objects} setObjects={setObjects} defaultOrder="location_storage" />
            </div>
        )
    });

    return (view)

}

export default CategoryTable;