import { useEffect } from "react";
import useGuruStore from "./stores/GuruStore";
import UISpinner from "../ui/UISpinner";
import UIToast from "../ui/UIToast";

import './styles/guru.scss';
import GuruUserLogin from "./components/GuruUserLogin";
import { ToastContainer } from "react-bootstrap";
import GuruAppLogin from "./components/GuruAppLogin";
import { useTranslation } from "react-i18next";

export default function Guru({children}) {

    const { i18n, t } = useTranslation();

    const { 
        initGuru, guruAppLang, 
        guruIsReady, guruAppIsInitialized, guruAppIsPrivate, guruAppHasPrivatePassword, guruAppIsAuthenticated, guruAppAuthenticateMode, guruClientIP,
        guruAppThemeMode, guruAppLastError, unsetGuruAppLastError
    } = useGuruStore();

    useEffect(() => {
        initGuru();
    }, [initGuru]);

    useEffect(() => {
        i18n.changeLanguage(guruAppLang);
    }, [guruAppLang, i18n]);

    const getToastError = (error) => {

        switch (error.type) {

            case 'no_login':
                return (
                    <UIToast key={error.id} title="Erreur !" message="Veuillez entrer un adresse e-mail." error onClose={() => unsetGuruAppLastError(error.id)} />
                )

            case 'no_key':
                return (
                    <UIToast key={error.id} title="Erreur !" message="Veuillez entrer un mot de passe." error onClose={() => unsetGuruAppLastError(error.id)} />
                )

            case 'no_confirm':
                return (
                    <UIToast key={error.id} title="Erreur !" message="Veuillez confirmer votre mot de passe." error onClose={() => unsetGuruAppLastError(error.id)} />
                )

            case 'bad_login':
                return (
                    <UIToast key={error.id} title="Erreur !" message="Utilisateur inconnu." error onClose={() => unsetGuruAppLastError(error.id)} />
                )

            case 'bad_key':
                return (
                    <UIToast key={error.id} title="Erreur !" message="Mauvais mot de passe." error onClose={() => unsetGuruAppLastError(error.id)} />
                )

            // case 'bad_confirm':
            //     return (
            //         <UIToast key={error.id} title="Erreur !" message="Les mots de passe ne sont pas identiques." error onClose={() => unsetGuruAppLastError(error.id)} />
            //     )

            // case 'no_reset_key':
            // case 'bad_reset_key':
            //     return (
            //         <UIToast key={error.id} title="Erreur !" message="Ce lien de réinitialisation n'est pas valide." error onClose={() => unsetGuruAppLastError(error.id)} />
            //     )
            //     break;

            default:
                break;
        }
    }

    if(!guruIsReady) return (<div className="Guru" data-theme-mode={guruAppThemeMode}>
        <UISpinner />
    </div>)

    if(!guruAppIsInitialized) return (<div className="Guru" data-theme-mode={guruAppThemeMode}>
        <div className="Guru-not-initialized">
            <span>🚀</span>
            <p>Prochainement ...</p>
        </div>
    </div>)

    if(guruAppIsPrivate && !guruAppIsAuthenticated) return (<div className="Guru" data-theme-mode={guruAppThemeMode}>    
        <div className="Guru-not-authenticated">
        <span>🔐</span>
        <p>{t('private_content')}</p>
        {guruAppAuthenticateMode === "user" && <GuruUserLogin />}
        {guruAppAuthenticateMode === "app" && guruAppHasPrivatePassword && <GuruAppLogin />}
        <ToastContainer position="bottom-end" style={{ paddingBottom: '1em', paddingRight: '1em' }}>
            {guruAppLastError.map((error) => {
                return getToastError(error)
            })}
        </ToastContainer>
</div>
    </div>)

    return (<div className="Guru" data-theme-mode={guruAppThemeMode}>
        {children}
    </div>)
}