import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from "react-router-dom";
import { toDataURL } from 'qrcode';

import { fetchStatusObjects } from '../services/inventory';
import { setColors, getStatusData } from '../libs/utils';

import AppHeader from '../components/AppHeader.jsx';
import StatusTable from '../components/StatusTable.jsx';

import UIIcon from '../ui/UIIcon';

import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import PageBreadcrumb from '../ui/Breadcrumb';

function StatusView() {

    const params = useParams();

    const [objects, setObjects] = useState([]);
    const [statusName, setStatusName] = useState("");
	const [objectsCount, setObjectsCount] = useState(0);
	const [QRsource, setQRsource] = useState("");

    const [statusData, setStatusData] = useState({}); 

	useLayoutEffect(() => {
	  	setColors();
	});

    useEffect(() => {
		if(params.name) {
			setStatusName(params.name);
			fetchStatusObjects(params.name).then((response) => {
				console.log("Status objects",response.result);
                setStatusData(getStatusData(params.name));
				setObjects(response.result);
				setObjectsCount(response.result.length);
			});
			const url = window.location+"";
        	toDataURL(url).then(setQRsource);
		};

	},[params.name]);

    return (
        <>
            <div className="App">
			<AppHeader />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2><UIIcon name={statusData.icon} className="me-2" />{statusData.label}</h2>
							<PageBreadcrumb page="status" />
						</div>
						<p className='ms-auto lead'><Badge bg="primary" text="black" className='me-2'>{objectsCount}</Badge> objet{objectsCount > 1 && `s`} ayant ce statut</p>
					</Stack>
					<StatusTable objects={objects} setObjects={setObjects} />
					
					<div className="d-none d-sm-block">
                		<img src={QRsource} alt="QR Code" />
            		</div>
                </section>
            </div>
            
        </>
    )

}

export default StatusView;