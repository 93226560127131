import { API_URL, APP_URL, PASSPORT_NAME } from "../guru/config";

export const fetchObjects = async () => {
  const API_CALL_URL = API_URL + '/inventory/objects';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  // console.info("Objects data", data.result);
  return data;
};

export const fetchConditionObjects = async (condition) => {
  const API_CALL_URL = API_URL + '/inventory/condition/' + condition;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchStatusObjects = async (status) => {
  const API_CALL_URL = API_URL + '/inventory/status/' + status;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchLocationObjects = async (location) => {
  const API_CALL_URL = API_URL + '/inventory/location/' + location;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchCategoryObjects = async (category) => {
  const API_CALL_URL = API_URL + '/inventory/category/' + category;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchObject = async (objectID) => {
  const API_CALL_URL = API_URL + '/inventory/object/' + objectID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Object data", data.result);
  return data;
};

export const fetchNames = async () => {
  const API_CALL_URL = API_URL + '/inventory/objects/names';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchCategories = async () => {
  const API_CALL_URL = API_URL + '/inventory/objects/categories';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchSubcategories = async () => {
  const API_CALL_URL = API_URL + '/inventory/objects/subcategories';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchLocationPlace = async () => {
  const API_CALL_URL = API_URL + '/inventory/objects/location_place';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchLocationStorage = async () => {
  const API_CALL_URL = API_URL + '/inventory/objects/location_storage';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchCollections = async () => {
  const API_CALL_URL = API_URL + '/inventory/collections';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchCollectionObjects = async (collection) => {
  const API_CALL_URL = API_URL + '/inventory/collections/' + collection;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const createObject = async (objectData) => {
  const API_CALL_URL = API_URL + '/inventory/object';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(objectData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const editObject = async (objectID, objectData) => {
  const API_CALL_URL = API_URL + '/inventory/object/' + objectID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(objectData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const editObjectsLocation = async (objectIDs, objectsLocation, objectsLocationStorage, objectsLocationToMove, objectsLocationNext) => {
  const API_CALL_URL = API_URL + '/inventory/objects';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const objectsData = {
    'objects_ids' : objectIDs,
    'object_location_place': objectsLocation,
    'object_location_storage': objectsLocationStorage,
    'object_location_to_move': objectsLocationToMove ? '1' : '0',
    'object_location_next': objectsLocationNext
  }
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(objectsData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const editObjectsCondition = async (objectIDs, objectsCondition) => {
  const API_CALL_URL = API_URL + '/inventory/objects';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const objectsData = {
    'objects_ids' : objectIDs,
    'object_condition': objectsCondition,
  }
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(objectsData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const editObjectsStatus = async (objectIDs, objectsStatus) => {
  const API_CALL_URL = API_URL + '/inventory/objects';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const objectsData = {
    'objects_ids' : objectIDs,
    'object_status': objectsStatus,
  }
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(objectsData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const deleteObject = async (objectID) => {
  const API_CALL_URL = API_URL + '/inventory/object/' + objectID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', APP_URL);
  const options = {
    method: 'DELETE',
    headers: headers,
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};
