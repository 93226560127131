import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";



import ObjectsList from './pages/ObjectsList';
import ObjectForm from './pages/ObjectForm';
import ObjectView from './pages/ObjectView'
import StatusView from './pages/StatusView';
import ConditionView from './pages/ConditionView';
import LocationView from './pages/LocationView';
import CategoryView from './pages/CategoryView';
import CollectionView from './pages/CollectionView';
import AppUI from './pages/AppUI';

import MenuApp from './menus/MenuApp';

import './i18n';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-dark-5/dist/css/bootstrap-nightfall.min.css';
import "./index.scss";
import Guru from './guru/Guru';
import useGuruStore from './guru/stores/GuruStore';
import useInventoryStore from './stores/InventoryStore';
import { Toast, ToastContainer } from 'react-bootstrap';
import UIToast from './ui/UIToast';
import useAppStore from './stores/AppStore';

function App() {

    const { guruIsLaunched, guruAppLastAction, unsetGuruAppLastAction } = useGuruStore();
    const { fetchData } = useInventoryStore();
    const { screenSize, setCurrentScreenSize } = useAppStore();

    useEffect(() => {
        guruIsLaunched && setCurrentScreenSize();
        guruIsLaunched && fetchData();
    }, [guruIsLaunched, fetchData]);
    

    useEffect(() => {
        window.addEventListener('resize', setCurrentScreenSize);
        
        return(() => {
            window.removeEventListener('resize', setCurrentScreenSize);
        })
      }, [screenSize, setCurrentScreenSize])

    const getToastAction = (action) => {

        switch (action.type) {

            case 'create_object':
                return (
                    <UIToast key={action.id} title="Objet crée !" message="L'objet a bien été crée" onClose={() => unsetGuruAppLastAction(action.id)} />
                )

            case 'edit_object':
                return (
                    <UIToast key={action.id} title="Objet modifié !" message="L'objet a bien été modifié" onClose={() => unsetGuruAppLastAction(action.id)} />
                )

            case 'edit_objects':
                return (
                    <UIToast key={action.id} title="Objets modifiés !" message="Les objets ont bien été modifiés" onClose={() => unsetGuruAppLastAction(action.id)} />
                )

            default:
                break;
        }
    }

    return (
        <Guru>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<ObjectsList />} />
                    <Route path="create" element={<ObjectForm mode="add" />} />
                    <Route path="duplicate/:id" element={<ObjectForm mode="duplicate" />} />
                    <Route path="edit/:id" element={<ObjectForm mode="edit" />} />
                    <Route path="view/:id" element={<ObjectView />} />
                    <Route path="status/:name" element={<StatusView />} />
                    <Route path="condition/:name" element={<ConditionView />} />
                    <Route path="location/:name" element={<LocationView />} />
                    <Route path="category/:name" element={<CategoryView />} />
                    <Route path="collection/:name" element={<CollectionView />} />
                    <Route path="ui" element={<AppUI />} />
                </Routes>
            </BrowserRouter>
            <MenuApp />
            <ToastContainer position="bottom-end" style={{ paddingBottom: '1em', paddingRight: '1em' }}>
                {guruAppLastAction.map((action) => {
                    return getToastAction(action)
                })}
            </ToastContainer>
        </Guru>
    );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);