import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Badge, Button, ButtonGroup, Stack, ToastContainer, Dropdown, DropdownButton } from 'react-bootstrap';

import AppHeader from '../components/AppHeader';
import ObjectsTables from '../components/ObjectsTables';
import ObjectsGrid from '../components/ObjectsGrid';
import TableColSelector from '../components/TableColSelector';

import UIIcon from '../ui/UIIcon';
import BasicToast from '../ui/Toast';
import PageBreadcrumb from '../ui/Breadcrumb';
import useInventoryStore from '../stores/InventoryStore';
import UISpinner from '../ui/UISpinner';
import ModalMoveObjects from '../modals/ModalMoveObjects';
import useAppStore from '../stores/AppStore';
import ModalChangeCondition from '../modals/ModalChangeCondition';
import ModalChangeStatus from '../modals/ModalChangeStatus';



function ObjectsLists() {

	const { t } = useTranslation();

	const { objectsIsFetched, objectsData, objectsShowed, 
		objectsViewMode, changeObjectsViewMode, 
		objectsViewType, setObjectsViewType,
		bulkEditMode, toggleBulkEditMode, bulkEditObjects
	} = useInventoryStore();

	const { showModal } = useAppStore();


	const [objects, setObjects] = useState([]);
	const [currentObjects, setCurrentObjects] = useState([]);
	const [objectsCount, setObjectsCount] = useState(0);
	const [alertAction, setAlertAction] = useState("");

	const [objectTotals, setObjectTotals] = useState({
		object: 0,
		book: 0,
		clothes: 0,
		vinyl: 0
	});



	useEffect(() => {

		if(objectsIsFetched) {
			setObjects(objectsData);
		}

	}, [objectsIsFetched, objectsData]);

	useEffect(() => {
		// const objectsData = objectsShowed;
		// const filteredObjects = (objectsViewType !== undefined && objectsViewType !== "all") ? objectsData.filter(object => object.type === objectsViewType) : objectsData;
			// setCurrentObjects(filteredObjects);
			setObjectsCount(objectsData.length);
			let totals = {
				all: 0,
				object: 0,
				book: 0,
				clothes: 0,
				vinyl: 0
			};
			objectsData.map((item) => {
				if (item.type === "object") {
					totals.object++;
				}
				if (item.type === "book") {
					totals.book++;
				}
				if (item.type === "clothes") {
					totals.clothes++;
				}
				if (item.type === "vinyl") {
					totals.vinyl++;
				}
				totals.all++;

			});
			setObjectTotals(totals);
	}, [objectsData])

	// useEffect(() => {
	// 	if (objectsViewType !== "all") {
	// 		const filteredObjects = objects.filter(object => object.type === objectsViewType);
	// 		setCurrentObjects(filteredObjects);
	// 		setObjectsCount(filteredObjects.length);
	// 	}
	// 	else {
	// 		setCurrentObjects(objects);
	// 		setObjectsCount(objects.length);
	// 	}

	// }, [objectsViewType]);




	return (
		<>
			<div className='App'>
				<AppHeader isHome />
				
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2>{t('page_title_object_list')}</h2>
							<PageBreadcrumb />
						</div>
						<Link to="/create" className="ms-sm-auto d-none d-sm-block">
							<Button variant="outline-primary" size="lg"><UIIcon name="plus" className="me-2" />{t('create_object')}</Button>
						</Link>
						<Link to="/create" className="mt-2 mb-2 d-grid d-sm-none w-100">
							<Button variant="outline-primary" size="lg"><UIIcon name="plus" className="me-2" />{t('create_object')}</Button>
						</Link>
					</Stack>
					{!objectsIsFetched && <UISpinner />}
					{objectsIsFetched && <>
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row">
						{/* <div className="ms-0 mt-3 mt-sm-0 mb-3 mb-sm-0">
							<ButtonGroup>
								<Button variant={objectsViewMode !== "table" ? 'outline-secondary' : 'secondary'} onClick={() => changeObjectsViewMode()}><UIIcon name="list" className="me-2" />Liste</Button>
								<Button variant={objectsViewMode !== "grid" ? 'outline-secondary' : 'secondary'} onClick={() => changeObjectsViewMode()}><UIIcon name="grid" className="me-2" />Grille</Button>
							</ButtonGroup>
						</div> */}
						<div className="ms-0 ms-sm-0 mb-3 mb-sm-0 d-blocks d-sm-block text-start">
							<ButtonGroup>
								<Button variant={objectsViewType !== "all" ? 'outline-secondary' : 'secondary'} onClick={() => { setObjectsViewType('all') }}><UIIcon name="infinity" className="me-2" />Tout<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.all}</Badge></Button>
								{objectTotals.object ? <Button variant={objectsViewType !== "object" ? 'outline-secondary' : 'secondary'} onClick={() => { setObjectsViewType('object') }}><UIIcon name="rectangle-barcode" className="me-2" />Objets<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.object}</Badge></Button> : ''}
								{objectTotals.book ? <Button variant={objectsViewType !== "book" ? 'outline-secondary' : 'secondary'} onClick={() => { setObjectsViewType('book') }}><UIIcon name="books" className="me-2" />Livres<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.book}</Badge></Button> : ''}
								{objectTotals.clothes ? <Button variant={objectsViewType !== "clothes" ? 'outline-secondary' : 'secondary'} onClick={() => setObjectsViewType('clothes')}><UIIcon name="clothes-hanger" className="me-2" />Vêtements<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.clothes}</Badge></Button> : ''}
								{objectTotals.vinyl ? <Button variant={objectsViewType !== "vinyl" ? 'outline-secondary' : 'secondary'} onClick={() => setObjectsViewType('vinyl')}><UIIcon name="turntable" className="me-2" />Vinyls<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.vinyl}</Badge></Button> : ''}
							</ButtonGroup>
						</div>
						<div className='ms-4 d-none d-lg-block'>
							<TableColSelector />
						</div>
						<div className='ms-4 d-sm-block'>
							<ButtonGroup>
								<Button variant={bulkEditMode ? `secondary` : `outline-secondary`} onClick={() => toggleBulkEditMode()}><UIIcon name="list-check" className={"me-2"} />Sélectionner</Button>
								{bulkEditObjects.length > 0 && <DropdownButton as={ButtonGroup} variant='outline-secondary' align="end">
									<Dropdown.Item eventKey="1" onClick={() => showModal('MoveObjects')}>Modifier l'emplacement</Dropdown.Item>
									<Dropdown.Item eventKey="2" onClick={() => showModal('ChangeCondition')}>Modifier l'état</Dropdown.Item>
									<Dropdown.Item eventKey="3" onClick={() => showModal('ChangeStatus')}>Modifier le statut</Dropdown.Item>
								</DropdownButton>}
							</ButtonGroup>
						</div>
					</Stack>
					{objectsViewMode === "table" && <div className="d-block d-sm-block">
						<ObjectsTables objects={objectsShowed} setObjects={setCurrentObjects} />
					</div>}
					{objectsViewMode === "grid" && <div className="d-block d-sm-block">
						<ObjectsGrid objects={currentObjects} setObjects={setCurrentObjects} />
					</div>}
					<p className='text-center mt-3'><Badge bg="primary">{objectsCount}</Badge> objet{objectsCount > 1 && `s`} dans l'inventaire</p>
					</>}
				</section>
			</div>
			<ToastContainer position="bottom-end" style={{ paddingBottom: 20, paddingRight: 20 }}>
				{alertAction}
			</ToastContainer>
			<ModalMoveObjects />
			<ModalChangeCondition />
			<ModalChangeStatus />
		</>
	);
}

export default ObjectsLists;